:root {
  .wrapper {
    height: auto;
    padding: 15px 40px 30px 24px;
    border-top: 4px solid var(--border-pair);
    @media only screen and (max-width: 400px) {
      padding: 15px 20px 0px 20px;
    }
  }

  .usdt-text {
    color: var(--color-date-value);
    font-size: 16px;
    margin-right: 8px;
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-value-1);
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  .contents {
    padding: 0px 0px 24px 0px;
  }

  .margin-box {
    width: auto;
  }

  .select-box {
    border: none !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    &:focus {
      border: none !important;
    }
  }

  .mt-3 {
    @media only screen and (max-width: 400px) {
      margin-top: 12px;
    }
  }

  .margin-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 400px) {
      margin-bottom: 3px;
    }
  }

  .margin-item-asset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 0 24px 0;
    @media only screen and (max-width: 400px) {
      padding: 21px 0 17px 0;
    }
  }

  .margin-item-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-available);
    white-space: nowrap;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .margin-item-percent {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: var(--color-available);
    position: relative;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }

    &:hover {
      .tooltip-unrealized {
        display: block;
      }
      .tooltip-balance {
        display: block;
      }
      .tooltip-margin {
        display: block;
      }
    }

    .margin-item-percent-label {
      color: var(--color-value-1);
      span {
        color: var(--color-available) !important;
      }
    }
    span.margin-item-percent-link {
      text-decoration: underline;
      color: var(--color-primary-1);
      cursor: pointer;
    }

    span.margin-item-percent-link-is-login {
      text-decoration: underline;
      color: var(--color-primary-1);
      cursor: not-allowed;
      pointer-events: none;
    }

    .tooltip-balance {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: var(--bg-tooltip-1);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      align-self: center;
      z-index: 10;
      left: -4px;
      top: 24px;
    }

    .tooltip-unrealized {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: var(--bg-tooltip-1);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      align-self: center;
      z-index: 10;
      left: -40px;
      top: 24px;
    }

    .tooltip-margin {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: var(--bg-tooltip-1);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      z-index: 10;
      left: 0px;
      top: 24px;
    }
  }

  .margin-item-label-asset {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-value-1);
  }

  .margin-item-ratio {
    // width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .margin-item-ratio-space {
      flex: 1;
    }
    .margin-item-ratio-right {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }

    .margin-item-ratio-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -8px;
      img {
        flex-shrink: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .option-precision {
    border: none !important;
    min-width: 132px;
    font-size: 16px !important;
    @media only screen and (max-width: 400px) {
      left: 0;
    }
  }
  .option-assets {
    border: none !important;
    min-width: 100px;
    font-size: 16px !important;
    @media only screen and (max-width: 400px) {
      left: 0;
    }
  }

  .option-asset {
    border: none !important;
    min-width: 80px;
    font-size: 16px !important;
  }

  .margin-item-ratio-img-circle {
    position: absolute;
    top: 0;
    left: -20px;
    transform: translate(0%, 50%);
    z-index: 0;
  }

  .margin-item-ratio-img-clockwise {
    position: absolute;
    top: 10px;
    left: -20px;
    transform: translate(0%, 50%);
    z-index: 1;
    animation: animationTurn 2s infinite;
  }

  .tif-select {
    display: flex;
    align-items: center;
    user-select: none;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: var(--color-coin-value);
      padding-right: 4px;

      @media only screen and (max-width: 567px) {
        font-size: 14px;
        line-height: 19px;
      }
    }

    .icon-up {
      transform: rotate(180deg);
      padding-top: 2px;
    }
  }

  @keyframes animationTurn {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(240deg);
    }
  }
}
