:root {
  .title-prefer {
    padding: 40px 40px 0 40px;
    position: relative;
    width: 440px;
    background-color: var(--bg-modal);
    @media only screen and (max-width: 567px) {
      width: auto;
      padding: 30px 16px 0 16px;
    }

    @media only screen and (max-width: 300px) {
      padding: 16px 16px 0 16px;
    }

    .title-header {
      color: var(--color-text) !important;
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      color: #333333;
      font-family: inherit;
      @media only screen and (max-width: 567px) {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      @media only screen and (max-width: 300px) {
        font-size: 18px;
        text-align: start;
      }
    }
  }

  .content {
    padding: 20px 40px 40px 40px;
    background-color: var(--bg-modal);
    @media only screen and (max-width: 567px) {
      padding: 12px 16px 16px 16px;
    }
    @media only screen and (max-width: 400px) {
      padding: 24px 16px 16px 16px;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      font-size: 18px;
      line-height: 21px;
      @media only screen and (max-width: 567px) {
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 19px;
      }

      @media only screen and (max-width: 300px) {
        font-size: 14px;
      }
      .symbol {
        color: var(--color-text);
      }

      .sell {
        color: #e02d3c;
      }

      .buy {
        color: #00b277;
      }

      .field {
        color: var(--color-label-4);
      }

      .value {
        color: var(--color-text);
      }
    }

    .br {
      border-top: 1px solid var(--bg-line);
      margin: 8px 0 24px 0;
    }
  }

  .info {
    display: flex;
    gap: 8px;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-label-4);

    & > span {
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    gap: 24px;
    @media only screen and (max-width: 300px) {
      gap: 10px;
    }

    .button {
      width: 100%;
      height: 50px;
      background: var(--bg-button-close);
      border-radius: 8px;
      cursor: pointer;
      font-size: 18px;
      line-height: 21px;
      color: var(--text-button);
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 400px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 300px) {
        font-size: 16px;
        height: 40px;
      }
    }

    .button-close {
      background: var(--bg-button);
      color: var(--text-title-input);
    }
    .buy-button {
      color: var(--color-white);
      background: #00b277;
    }

    .sell-button {
      color: var(--color-white);
      background: #e02d3c;
    }
  }
}
