:root {
  .content-wrapper {
    color: var(--body-text);
    padding-bottom: 4px;
  }
  .dialog-title {
    text-align: center;
    font-weight: 700;
    font-size: 34px;
    color: var(--color-value-1);
    font-family: inherit;
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
  }
  .dialog-content {
    @media only screen and (max-width: 400px) {
      padding: 8px 7px !important;
    }
  }
  .dialog-desc {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: var(--color-modal-desc);
    font-family: inherit;
  }
  .warning-icon {
    margin-bottom: 45px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 24px;
    }
  }
  .button-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    padding: 0 30px 30px 30px;
    gap: 24px;
    .close-dialog {
      width: 218px;
      color: var(--color-background-text_button_confirm);
      background-color: var(--color-tab);
      text-transform: unset;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      font-family: inherit;
    }
    .confirm-close-btn {
      width: 218px;
      background: var(--color-background-order-form_cross);
      text-transform: unset;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      font-family: inherit;
    }
    @media only screen and (max-width: 400px) {
      gap: 15px;
      padding-bottom: 0px !important;
    }
    @media only screen and (max-width: 280px) {
      gap: 6px;
    }
  }

  @media only screen and (max-width: 768px) {
    .dialog-title {
      font-size: 18px;
    }
    .dialog-desc {
      font-size: 14px;
    }
    .button-wrapper {
      .close-dialog {
        font-size: 14px;
      }
      .confirm-close-btn {
        font-size: 14px;
      }
    }
  }

  .dialog-icon {
    margin-top: 37px;
    margin-bottom: 45px;
  }

  .action-dialog {
    justify-content: center;
    margin-top: 28px;
    @media only screen and (max-width: 400px) {
      margin-top: 18px;
    }
    @media only screen and (max-width: 280px) {
      margin-top: 5px;
    }
  }
}
