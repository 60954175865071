:root {
  .wrapper {
    position: relative;
    z-index: 2;

    > div:hover .content {
      display: block;
    }

    .title {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #00ddb3;
      font-family: inherit;
    }
    .content {
      display: none;
      position: absolute;
      min-height: auto;
      border-radius: 8px;
      right: 0px;
      padding: 15px 15px 3px 15px;
      background: var(--bg-modal);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      border: none;
      width: 120px;
      font-family: inherit;

      .fee-header {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 12px;
        color: var(--color-value-1);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      .fee-detail {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: var(--color-label-2);
        }

        div {
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          color: var(--color-value-1);
        }
      }
    }
  }
}
