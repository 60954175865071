:root {
  .wrapper {
    @media only screen and (max-width: 500px) {
      min-width: 343px;
    }
    @media only screen and (max-width: 300px) {
      min-width: 243px;
    }
  }

  .content {
    text-align: center;
    padding: 50px 50px 50px !important;
    background-color: var(--bg-modal);
    @media only screen and (max-width: 500px) {
      padding: 33px 16px 30px 16px !important;
    }

    .text-one {
      font-size: 20px;
      font-weight: 600;
      color: var(--text-terminated);
      margin-top: 20px;
      @media only screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
    .text-two {
      font-size: 20px;
      font-weight: 600;
      color: var(--text-terminated);
      margin-bottom: 40px;
      @media only screen and (max-width: 500px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    > button {
      font-size: 18px;
      padding: 15px;
      color: #333333;
      background-color: var(--color-bg-btn-3);
      border-radius: 8px;
      border: none;
      cursor: pointer;
      width: 100%;
      @media only screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
}
