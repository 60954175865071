:root {
  .calculator-wrapper {
    position: fixed;
    top: 80px;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, 0);
    max-height: 90vh;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid var(--border-calculator);
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    @media screen and (max-width: 650px) {
      top: 0px;
      z-index: 1000;
      width: 100%;
      overflow-y: overlay;
      max-height: 100vh;
      &::-webkit-scrollbar {
        scrollbar-width: thin;
        width: 6px;
        height: 6px;
        background: var(--background-color);
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--bg-scroll-thumb-calulator);
      }
      &::-webkit-scrollbar-track {
        background: var(--bg-scroll-track-calulator);
      }
    }
    @media screen and (max-width: 400px) {
      max-height: 100vh;
    }
  }
  .wrapper-title {
    background-color: var(--bg-modal-pre);

    @media screen and (max-width: 900px) {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .calculator {
    background-color: var(--bg-modal-pre);
    width: 858px;
    @media screen and (max-width: 900px) {
      width: 500px;
      // width: 100%;
    }
    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  .tab-calculator {
    display: flex;
    gap: 40px;
    margin-bottom: 14px;
    padding: 0 16px;

    &::after {
      background-color: var(--border-line-cal);
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 43px;
      left: 0;
    }

    .tab-item {
      font-size: 18px;
      color: var(--text-tab);
      position: relative;
      cursor: pointer;
    }
    .tab-item-active {
      color: var(--text-tab-active);
      &::after {
        background-color: var(--color-primary-1);
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        top: 20px;
        left: 0;
        margin-top: 12px;
        border-radius: 20px;
      }
    }
  }

  .modal-content {
    position: relative;
    padding-bottom: 0 !important;
    background-color: var(--bg-modal-pre);
  }

  .wrapper-title {
    padding: 16px 24px 0 24px;
  }

  .wrapper-content {
    display: flex;
    min-width: 794px;
    padding-left: 16px;
    padding-bottom: 16px;
    &__left {
      padding: 20px 20px 0 0;
      border-right: 1px solid var(--border-line-cal);
      width: 60%;

      &__type-trade {
        display: flex;
        background: var(--bg-select-cal);
        border-radius: 8px;
        margin-bottom: 62px;
        user-select: none;
        color: #ffffff;

        div {
          padding: 12px;
          width: 50%;
          text-align: center;
          cursor: pointer;
          font-weight: 600;
          font-size: 18px;
          border-radius: 8px;
          color: var(--text-tab-inactive-2);
        }
      }
    }

    &__right {
      padding: 20px;
      width: 40%;
    }
  }

  .wrapper-content-mb {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &__left {
      padding: 0px 20px 0px 0;
      width: 100%;

      &__type-trade {
        display: flex;
        background: var(--bg-select-cal);
        border-radius: 8px;
        color: #ffffff;

        div {
          padding: 12px;
          width: 50%;
          text-align: center;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          border-radius: 8px;
          color: var(--text-tab-inactive);
        }
      }
    }

    &__right {
      width: 100%;
    }
  }

  .type-active {
    background: var(--color-primary-2);
    color: var(--color-dialog-bg) !important;
  }
  .type-active-short {
    background: #e02d3c;
    color: var(--color-dialog-bg) !important;
  }

  .text-medium {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-tab-active);
    text-align: left;
  }

  .text-medium-mb {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-tab-active);
    text-align: left;
  }

  .result-content {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-tab-inactive);
  }

  .text-dark {
    color: var(--text-tab-active);
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  input::placeholder {
    font-family: inherit;
    line-height: 21px;
    color: var(--text-leverange);
  }

  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin: 10px 0px;
    background-color: var(--bg-select-cal) !important;
    gap: 12px;
    .input {
      width: 100%;
      padding: 8px 0px !important;
    }

    input {
      color: var(--text-tab-active);
      width: 100%;
      background: transparent;
    }
  }

  .token-symbol {
    height: 100%;
    padding: 0px 15px;
    font-weight: 400;
    color: var(--text-tab-active);
  }

  .text-last {
    font-weight: 400;
    font-size: 18px;
    color: var(--color-primary-1);
    cursor: pointer;
    padding-left: 12px;
    white-space: nowrap;
  }

  .token-symbol-mb {
    height: 100%;
    padding: 0px 20px;
    font-weight: 400;
    color: var(--text-tab-active);
    font-size: 14px;
  }

  .input-title {
    font-weight: 400;
    font-size: 18px;
    color: var(--text-title-input);
    text-align: left;
  }

  .input-title-mb {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-title-input);
    text-align: left;
  }

  .input-mb::placeholder {
    font-size: 12px;
    font-weight: 400;
  }

  .wrapper-input {
    margin-top: 16px;
  }

  @media (max-width: 900px) {
    .button-calculate {
      cursor: pointer;
      width: 100%;
      background: var(--color-primary-1);
      color: var(--color-value-1);
      border-radius: 8px;
      padding: 14px;
      border: none;
      font-weight: 400;
      font-size: 16px !important;
      margin-top: 30px;
      margin-bottom: 14px;

      &:disabled {
        background: var(--bg-btn-cal-disable);
        color: var(--text-btn-disable);
      }
    }
  }

  .button-calculate {
    cursor: pointer;
    width: 100%;
    background: var(--color-primary-1);
    color: var(--color-btn-cal);
    border-radius: 8px;
    padding: 14px;
    border: none;
    font-weight: 400;
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 14px;

    &:disabled {
      background: var(--bg-btn-cal-disable);
      color: var(--text-btn-disable);
    }
  }

  .content-open {
    margin-bottom: 40px;

    .open-price-row {
      display: grid;
      background: var(--bg-input-open-price);
      border-radius: 8px;
      padding: 6px 15px;
      grid-template-columns: 0.8fr 2fr 2fr 0.3fr;
      align-items: center;
      margin-bottom: 10px;
      text-align: left;

      .input,
      input {
        background: transparent;
        width: 80%;
      }

      img {
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .index-open-input {
      color: var(--text-tab-active);
    }

    .input-open-price {
      color: var(--text-tab-active);
      &:focus {
        border: 1px solid #00ddb3;
      }
    }

    .open-price-title {
      background: none;
      color: var(--text-leverange);
      margin: 20px 0px;
    }

    .add-postion {
      color: var(--color-primary-1);
      font-weight: 400;
      font-size: 18px;
      margin-top: 20px;
      cursor: pointer;
      width: fit-content;
    }
  }

  .content-table {
    max-height: 30vh;
    overflow: auto;
    width: 100%;
  }

  .wrapper-right-maxopen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-medium-thin {
    font-weight: 400;
    font-size: 16px;
    color: var(--text-leverange);
    text-align: left;
  }

  .text-medium-thin-mb {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-leverange);
    text-align: left;
  }

  .text-medium-thin-mobile {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-tab-inactive);
    margin-top: 20px;
    text-align: left;
  }

  .error-maximum-insufficient {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #e02d3c;
    margin-top: 16px;
    text-align: left;
  }

  .error-maximum {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #e02d3c;
    text-align: left;
  }

  .wrapper-content-select {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
    span {
      font-weight: 400;
      font-size: 16px;
      color: var(--text-tab-active);
      background: var(--bg-select-cal);
      border-radius: 8px;
      padding: 12px 15px;
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        padding: 10px;
        margin-left: 10px;
      }
      @media screen and (max-width: 280px) {
        margin-left: 0px;
      }
    }
    @media screen and (max-width: 280px) {
      gap: 0;
    }
  }
  .select-value {
    background: var(--bg-select-cal);
    border-radius: 8px;
    padding: 10px 15px;
    width: 247px;
    display: flex;
    gap: 32px;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-tab-active);
    cursor: pointer;
    margin: 0 0 20px 32px;
    justify-content: space-between;
    align-items: center;

    svg path {
      stroke: var(--color-label-2);
    }

    @media screen and (max-width: 350px) {
      width: 154px !important;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .type {
    @media screen and (max-width: 280px) {
      width: 85px !important;
    }
  }

  .select-item-active {
    color: var(--color-primary-1) !important;
  }

  .select-list {
    margin: 0 0 20px 32px;
    position: absolute;
    background: var(--bg-select);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 247px;
    padding: 15px;
    padding-bottom: 24px;
    z-index: 10000;
    top: 45px;
    &::-webkit-scrollbar {
      scrollbar-width: thin;
      width: 6px;
      height: 6px;
      background: var(--background-color);
      border-radius: 20px;
    }
    @media screen and (max-width: 300px) {
      width: 154px !important;
    }
  }

  .max-width {
    max-height: 325px;
    overflow-y: auto;
  }

  .mode {
    color: var(--text-tab-active);
  }

  .select-wrapper {
    position: relative;
    width: fit-content;
    @media screen and (max-width: 650px) {
      // padding-top: 24px;
    }
  }

  .select-pair {
    @media screen and (max-width: 650px) {
      padding-top: 24px;
    }
  }

  .pt-none {
    @media screen and (max-width: 400px) {
      padding-top: 0px !important;
    }
  }

  .pt-liq {
    @media screen and (max-width: 400px) {
      top: 42px !important;
    }
  }

  .select-search {
    display: flex;
    gap: 12px;
    background: var(--bg-select-search);
    padding: 10px 15px;
    border-radius: 8px;
    input {
      border: none;
      background-color: transparent;
      width: 80%;
      font-weight: 400;
      font-size: 18px;
      color: var(--text-tab-active);

      &:focus-visible {
        outline: none !important;
      }
    }
  }

  .select-item {
    padding-top: 21px;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-leverange);
    text-align: left;

    &:hover {
      color: var(--color-primary-1);
    }
  }

  .choose-trigger {
    div:first-child {
      padding: 12px 15px !important;
    }

    .single-value {
      color: red !important;
    }
  }

  .tooltip-value {
    display: flex;
    color: var(--text-tab-active);
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    cursor: text;
    &:hover {
      background-color: var(--bg-modal-pre);
    }
  }

  .tooltip-value-mb {
    color: var(--text-tab-active);
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    display: flex;
  }

  .tab-calculator-mb {
    display: flex;
    gap: 40px;
    margin-bottom: 14px;
    padding: 0 16px;
    // max-width: 425px;
    overflow-x: auto;
    overflow-y: hidden;
    height: 45px;

    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }

    &::after {
      background-color: var(--border-line-cal);
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 43px;
      left: 0;
    }

    .tab-item {
      font-size: 14px;
      color: var(--text-tab);
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      user-select: none;
    }
    .tab-item-active {
      color: var(--text-tab-active);
      &::after {
        background-color: var(--color-primary-1);
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        top: 20px;
        left: 0;
        margin-top: 12px;
        border-radius: 20px;
      }
    }
  }

  .text-result {
    color: var(--text-leverange);
  }

  .text-result-mb {
    font-size: 14px;
    color: var(--text-leverange);
  }

  .text-open {
    color: var(--text-leverange);
  }

  .text-open-mb {
    font-weight: 400;
    font-size: 14px;
  }

  .wrapper-table {
    width: 100%;
    overflow-x: auto;
  }

  .table-mb {
    width: 100%;
    @media screen and (max-width: 400px) {
      width: 780px;
      overflow-x: auto;
    }
  }

  .mt-52 {
    margin-top: 52px;
  }

  @media (max-width: 900px) {
    .select-value {
      background: var(--bg-select-cal);
      border-radius: 8px;
      padding: 10px 15px;
      width: 247px;
      display: flex;
      gap: 32px;
      font-weight: 400;
      font-size: 18px;
      color: var(--text-tab-active);
      cursor: pointer;
      margin: 0 0 20px 17px;
      justify-content: space-between;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
    }

    .select-list {
      margin: 0 0 20px 17px;
      position: absolute;
      background: var(--bg-select);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 247px;
      padding: 15px;
      padding-bottom: 24px;
      z-index: 10000;
      top: 67px;

      @media screen and (max-width: 650px) {
        // top: 62px;
      }
    }

    .select-item {
      padding-top: 21px;
      font-weight: 400;
      font-size: 16px !important;
      color: var(--text-leverange);
      text-align: left;
    }

    .select-search {
      display: flex;
      gap: 12px;
      background: var(--bg-select-search);
      padding: 10px 15px;
      border-radius: 8px;
      input {
        border: none;
        background-color: transparent;
        width: 80%;
        font-weight: 400;
        font-size: 16px;
        color: var(--text-tab-active);

        &:focus-visible {
          outline: none !important;
        }
      }
    }
  }

  .text-value {
    margin-right: 5px;
    // max-width: 109px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // text-align: right;
  }

  .text-value-empty {
    margin-right: 5px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    color: var(--text-leverange);
  }

  .text-pair {
    color: var(--text-leverange);
  }

  .rotate {
    transform: rotate(3.142rad);
  }
  .icon-direct {
    position: absolute;
    cursor: pointer;

    rect {
      fill: transparent;
    }
    path {
      stroke: var(--color-label-2);
    }
  }
  .icon-prev {
    transform: rotate(180deg);
    left: 20px;
  }
  .icon-next {
    position: absolute;;
    right: 20px;
  }
}
