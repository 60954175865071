:root {
    .wrapper {
        display: flex;
        overflow: hidden;
        height: 100%;
        flex-direction: row;
        background-color: var(--color-background-order-wrapper);
        .orders-tab {
            width: 337px;
            flex-shrink: 0;
            .tab-items {
                font-size: 20px;
                color: var(--color-tab-item);
                padding: 20px 50px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
            }
            .tab-items-active {
                background-color: var(--color-tab-items-active);
                border-right: 6px solid #00ddb3;
            }
        }
    }
    .orders-content {
        border-left: 3px solid var(--color-tab-border-right);
        flex: 1;
        width: calc(100% - 337px);
        min-height: 80vh;
    }
    @media only screen and (max-width: 970px) {
        .orders-tab {
            display: none;
        }
        .orders-content {
            width: 100%;
            border-left: none;
        }
    }
}