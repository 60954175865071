@import 'src/styles/App.module.scss';

:root {
  .theme-select__control {
    border-radius: 4px !important;
    background: var(--bg-overview-4) !important;
    border: 1px solid transparent !important;
    color: var(--text-body-color);
    min-height: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    cursor: pointer;
    &--is-focused {
      border: 1px solid transparent !important;
      outline: none !important;
    }
    .theme-select__value-container {
      // height: 34px;
      padding: 0;
    }
    .theme-select__single-value {
      color: var(--color-label-4);
      text-overflow: initial;
      max-width: calc(100% - 4px) !important;
      font-size: 14px;
    }
    .theme-select__multi-value {
      background: var(--color-primary);
      color: var(--color-white);
      border-radius: $space-4;
      height: 26px;
      margin: 5px 5px 5px 0;
      display: flex;
      align-items: center;

      .theme-select__multi-value__label {
        color: var(--color-white);
        padding: 5px 6px;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .theme-select__multi-value:first-child {
      margin-left: 0px;
    }
    .theme-select__multi-value__remove:hover {
      background: var(--color-primary);
      cursor: pointer;
      svg {
        color: var(--color-white);
      }
    }
    .theme-select__placeholder {
      margin-left: $space-12;
      color: var(--color-title-active);
      font-size: 14px;
    }
  }

  .theme-select__control--is-disabled {
    .theme-select__multi-value--is-disabled {
      background: var(--color-placeholder);
    }
    .theme-select__multi-value__remove {
      background: var(--color-placeholder);
    }
  }

  .theme-select__menu {
    display: flex;
    right: 0;
    text-align: left;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08) !important;
    z-index: 100 !important;
    margin: 4px 0 !important;
    color: var(--title-active) !important;
    background: var(--color-background-pagination) !important;
    border-radius: 8px;
    border: none !important;
    width: fit-content;
  }

  .theme-select__control:focus {
    outline: none;
    border: none;
  }
  .theme-select__control--menu-is-open:focus {
    outline: none;
    border: none;
  }

  .css-gak63a-control {
    box-shadow: none;
  }

  // state
  .theme-select__value-container:focus {
    outline: none;
  }

  .theme-select__value-container--has-value {
    padding-left: 5px;
    padding-right: 5px !important;
    outline: none !important;
    justify-content: end;
    width: 85px;
    text-align: end;
    @media only screen and (max-width: 400px) {
      padding-left: 0px !important;
    }
  }
  .theme-select__menu-list {
    padding: 5px !important;
    cursor: pointer !important;
    div {
      background: transparent !important;
    }

    > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .theme-select__option {
    border-radius: 4px;
    background: var(--background-color) !important;
    color: var(--color-label-4) !important;
    white-space: nowrap;

    cursor: pointer;
    font-size: 16px;
  }
  .theme-select__option:hover {
    color: var(--color-hover-select) !important;
  }
  .theme-select__option--is-selected {
    color: var(--color-hover-select) !important;
    border-radius: 4px;
  }
  .css-2b097c-container:focus {
    border: none !important;
    outline: none !important;
  }

  .theme-select__indicator-separator {
    background-color: transparent !important;
  }
  .theme-select__indicators {
    background-color: transparent !important;
    // width: 40px;
  }
  .theme-select__dropdown-indicator {
    color: var(--text-input) !important;
  }
  .css-tlfecz-indicatorContainer {
    padding: 0;
    margin-right: 16px !important;
  }

  .css-1gtu0rj-indicatorContainer {
    padding: 0;
    margin-right: 16px !important;
  }
}
.is-invalid {
  border: 1px solid red;
  border-radius: 8px;
  .theme-select__control {
    border: none !important;
  }
}
