:root {
  .container {
    background-color: var(--bg-overview-4);
    @media only screen and (max-width: 300px) {
      border-right: none;
    }
    .box-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      overflow: auto;
      &::-webkit-scrollbar {
        scrollbar-width: thin;
        height: 0px;
      }
    }

    .tab-wrapper {
      display: flex;
      padding-left: 39px;
      .tab {
        padding: 7px 0;
        margin-right: 26px;
        cursor: pointer;
        color: var(--color-tab);
        font-size: 18px;
        white-space: nowrap;
        @media only screen and (max-width: 400px) {
          font-size: 16px;
        }
      }
      .active {
        border-radius: 5px 5px 0 0;
        color: var(--color-primary-1);
      }
    }
    .hidden-btn {
      margin-right: 20px;
      display: flex;
      align-items: center;

      .container {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        font-size: 20px;
        margin-top: -2px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 20px;
      }

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        left: 0px;
        top: 2px;
        height: 19px;
        width: 19px;
        background-color: var(--checkmark);
        border-radius: 2px;
      }

      .container input:checked ~ .checkmark {
        background-color: var(--color-primary-1);
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      .container input:checked ~ .checkmark:after {
        display: block;
      }

      .container .checkmark:after {
        left: 6.5px;
        top: 2.5px;
        width: 4px;
        height: 10px;
        border: solid black;
        border-width: 0 2.5px 2.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .hidden-text {
        color: var(--text-tab-inactive);
        font-size: 16px;
        white-space: nowrap;
        @media only screen and (max-width: 400px) {
          font-size: 14px;
        }
      }

      // .check-box {
      //   color: #424754;
      //   &:hover {
      //     background-color: var(--bg-order);
      //   }
      // }
    }
  }
  // .icon-checked {
  //   color: var(--color-primary-1) !important;
  // }

  @media only screen and (max-width: 768px) {
    .tab-wrapper {
      padding-left: 25px !important;
    }
    .hidden-btn {
      margin-right: 10px !important;
      display: flex;
      align-items: center;
      .container {
        padding-left: 25px !important;
      }
      .hidden-text {
        width: 120px;
      }
    }
  }
}
