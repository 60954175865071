:root {
  .future-wrapper {
    display: flex;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
    .future-wrapper-left {
      background-color: var(--bg-order);
      width: calc(100% - 410px);
      border-right: 4px solid var(--border-pair-2);
      @media only screen and (max-width: 1400px) {
        width: calc(100% - 350px);
      }
      @media only screen and (max-width: 900px) {
        width: calc(100% - 300px);
      }
      @media only screen and (max-width: 700px) {
        width: 100%;
        overflow-x: hidden;
      }
    }
  }
  .dashboard {
    width: 100%;
    display: flex;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  .market-order {
    flex: 1 0 auto;
    width: 410px;
    border-right: 1px solid var(--color-line);
    background-color: var(--bg-order);
    @media only screen and (max-width: 1400px) {
      width: 350px;
    }
    @media only screen and (max-width: 1400px) {
      width: 300px;
    }
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .order-book {
    background-color: var(--bg-overview-4);
    height: 770px;
    flex: 1 0 auto;
    width: 294px;
    border-bottom: 4px solid var(--border-pair);
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .trading {
    flex: 1 0 auto;
    width: calc(100% - 330px);
    border-right: 4px solid var(--border-pair);
    border-bottom: 4px solid var(--border-pair);
    z-index: 0;
    padding-bottom: 4px;
    background-color: var(--bg-overview-4);
    @media only screen and (max-width: 700px) {
      width: 100%;
      height: 464px;
    }
  }
  .trading-wrapper {
    display: flex;
    flex-direction: column;
    .box_container {
      display: flex;
      width: 100%;
      position: relative;
      background-color: var(--bg-overview-4);
      .pair-statistics {
        display: flex;
        border-bottom: 4px solid var(--border-pair);
        align-items: center;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        padding-top: 5px;
        @media only screen and (max-width: 770px) {
          display: block;
        }
        @media only screen and (max-width: 300px) {
          border-right: none;
        }
        .statistics-wrapper {
          display: flex;
          .statistics-container {
            display: flex;
            margin-right: -400px;
            position: relative;
            @media only screen and (max-width: 770px) {
              height: 40px;
            }
            .statistic-item-price {
              margin-top: 21px;
              margin-bottom: 21px;
              width: fit-content;
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              max-height: 35px;
              display: none;
              @media only screen and (max-width: 770px) {
                display: block;
                margin-top: 12px;
              }
              .statistic-item-value-price {
                font-size: 12px;
                font-weight: 400;
                margin-right: 20px;
                text-align: left;
                color: #888888;
                @media only screen and (max-width: 770px) {
                  display: none;
                }
              }
            }
            .statistic-item {
              margin-top: 21px;
              margin-bottom: 21px;
              width: fit-content;
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              max-height: 35px;
              .statistic-item-label {
                font-size: 16px;
                color: var(--text-tab);
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 6px;
                white-space: nowrap;
                user-select: none;
                @media only screen and (max-width: 770px) {
                  font-size: 10px;
                }
              }
              .statistic-item-value {
                font-size: 14px;
                font-weight: 400;
                margin-left: 20px;
                margin-right: 20px;
                text-align: left;
                color: var(--color-value-1);
                white-space: nowrap;
                position: relative;
                @media only screen and (max-width: 770px) {
                  font-size: 12px;
                  margin-bottom: 9px;
                }
              }
              .statistic-item-last-price {
                font-size: 20px;
                font-weight: 500;
                width: 100%;
                text-align: center;
                @media only screen and (max-width: 770px) {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
              .network {
                display: flex;
                cursor: pointer;
                .symbol {
                  font-size: 24px;
                  font-weight: 600;
                  color: var(--color-value-1);
                  margin-left: 20px;
                  @media only screen and (max-width: 770px) {
                    font-size: 16px;
                  }
                }
                .drop-icon {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 16px;
                  position: relative;
                  &::after {
                    content: '';
                    height: 103px;
                    width: 4px;
                    background-color: var(--border-pair);
                    position: absolute;
                    margin-left: 30px;
                  }
                }
              }
            }
          }
        }
      }
      .statistic-prev {
        left: 0px;
        height: 92%;
        width: 68px;
        margin: 0px;
        left: 0px;
        border-radius: 4px;
        background-image: linear-gradient(-270deg, #fafafc 71.88%, rgba(250, 250, 252, 0) 100%);
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .statistic-next {
        background-image: var(--color-arrowNext);
        height: 92%;
        width: 68px;
        right: 0px;
        margin: 0px;
        border-radius: 4px;
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .hidden-mb {
    @media only screen and (max-width: 770px) {
      display: none !important;
    }
  }
  .hidden-pair-mb {
    display: none !important;
    @media only screen and (max-width: 770px) {
      display: block !important;
    }
  }
  .statistic-item-label-perpetual {
    font-size: 16px;
    color: var(--text-tab);
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 770px) {
      display: none !important;
    }
  }
  .scroll-mb {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      scrollbar-width: thin;
      height: 0px;
    }
  }
  .flex {
    display: flex;
    white-space: nowrap;
    gap: 8px;
    .funding {
      color: #db761a;
      @media only screen and (max-width: 770px) {
        width: 56px;
      }
    }
  }
  .time-change {
    display: flex;
    .price-change {
      padding-right: 4px;
    }
  }
  .increase {
    color: #00b277;
  }
  .decrease {
    color: #e02d3c;
  }
  .price-change-percent {
    display: flex;
    .icon-status {
      padding-left: 10px;
    }
  }
  .volume-wrap {
    position: relative;
    &:hover {
      .tooltip-volume {
        display: block;
      }
    }
    .tooltip-volume {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      align-self: center;
      z-index: 10;
      left: 14px;
      top: 0px;
    }
  }
  .change-percent {
    position: relative;
    &:hover {
      .tooltip-change-percent {
        display: block;
      }
    }
    .tooltip-change-percent {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      z-index: 10;
    }
  }
}
