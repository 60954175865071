:root {
  .navbar {
    box-shadow: none;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
  }
  .footer {
    display: flex;
    padding: 60px 260px 60px 260px;
    background-color: var(--color-background-footer);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1820px) {
      padding: 60px 165px 60px 100px;
    }

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 25px 16px 40px 16px;
      align-items: center;
      display: flex;
    }
    .footer-logo {
      @media only screen and (max-width: 1200px) {
        display: flex;
        margin-bottom: 50px;
        width: 100%;
        .logo-box {
          width: 100%;
        }
        .logo {
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          justify-content: space-between;
        }
      }
      .logo {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
      }
      .footer-text {
        text-align: left;
        font-size: 16px;
        color: var(--color-label-2);
        .text {
          display: flex;
          color: var(--color-text-footer);
        }
        > *:last-child {
          margin-top: 8px;
        }
      }
      @media only screen and (max-width: 650px) {
        margin-bottom: 25px;
      }
    }

    .group-link {
      .links-group {
        &:first-child {
          display: none;
          @media only screen and (max-width: 650px) {
            width: 122px;
            display: block !important;
          }
        }
        &:last-child {
          display: none;
          @media only screen and (max-width: 650px) {
            display: block !important;
          }
        }
      }
    }
    .links-group {
      .links-title {
        display: flex;
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      &:nth-child(5) {
        @media only screen and (max-width: 650px) {
          width: 122px;
        }
      }
    }
    .links-item {
      display: flex;
      .link-item-href {
        text-decoration: none;
        font-size: 16px;
        color: var(--color-text-footer);
        margin-bottom: 10px;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .social-icon {
      text-align: right;
      max-width: 100px;
      > a {
        cursor: pointer;
        height: max-content;
        img {
          &:nth-child(1) {
            margin-right: 15px;
            margin-bottom: 15px;
          }
          &:nth-child(3) {
            margin-right: 15px;
          }
        }
        
      }
    }
    .social-icon-mb {
      display: flex;
      align-items: center;
      > a {
        cursor: pointer;
        &:nth-child(1) {
          margin-right: 12px;
        }
        &:nth-child(2) {
          margin-right: 12px;
        }
        &:nth-child(3) {
          margin-right: 12px;
        }
      }
    }
    .links-group-2 {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 24px;
      @media only screen and (max-width: 650px) {
        display: none;
      }
      .title-contact {
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      .text-contact {
        font-size: 16px;
        color: var(--color-text-footer);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .middle {
      display: flex;
      gap: 77px;
      text-align: left;
      flex-wrap: wrap;
      @media only screen and (max-width: 650px) {
        row-gap: 20px;
        column-gap:32px;
      }
    }
  }
  .line {
    height: 1px;
    background-color: #2d303b;
    width: 100%;
    margin-top: 25px;
  }
  .about-us {
    display: block !important;
    @media only screen and (max-width: 650px) {
      display: none !important;
    }
  }
}
