:root {
  tbody tr.no-color {
    td {
      padding: 12px 0;
    }
    &:hover {
      background-color: unset;
    }
  }
  .orders-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .open-order-details {
    margin: 40px 0 120px 40px;
    text-align: start;
    flex: 1;
    display: flex;
    flex-direction: column;
    .tabs-area {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .tab-details {
      font-size: 20px;
      color: var(--color-tab-inactive);
      width: fit-content;
      height: 42px;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: -6px;
      }
    }
    .tab-details-active {
      flex-shrink: 0;
      font-size: 20px;
      color: var(--color-tab-active);
      flex-shrink: 0;
      height: 42px;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #00ddb3;
        border-radius: 20px;
        position: absolute;
        bottom: -6px;
      }
    }
  }
  .loading-box {
    min-height: 253px;
    width: 1280px;
    flex: 1;
    > * div {
      height: 100% !important;
    }
  }
  .filter-area {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 1280px;
    gap: 40px;
    flex-shrink: 0;
    flex-wrap: wrap;
    @media only screen and (max-width: 1661px) {
      width: auto;
    }

    .btn-search {
      font-size: 18px;
      color: var(--color-value-1);
      border-radius: 8px;
      background-color: var(--color-bg-btn-2);
      border: none;
      cursor: pointer;
      user-select: none;
      height: 49px;
      width: 112px;
    }
    .btn-reset {
      font-size: 18px;
      color: var(--color-value-4);
      border-radius: 8px;
      background-color: #00ddb3;
      border: none;
      cursor: pointer;
      user-select: none;
      height: 49px;
      width: 112px;
      font-family: inherit;
      &:hover {
        background-color: #57fbdc;
      }
    }
    .select-asset {
      width: 100%;
    }
    .select-type {
      width: 100%;
    }
  }

  .table-content {
    overflow: auto;
    margin-right: 16px;
    max-width: 78%;
    @media only screen and (max-width: 1860px) {
      max-width: 1280px;
    }
    // &::-webkit-scrollbar-thumb {
    //   background: var(--color-scrollbar) !important;
    // }
    // @media only screen and (max-width: 400px) {
    //   padding-right: 0px;
    // }
    > table {
      margin-bottom: 40px;
      > thead {
        height: 49px;
        > tr {
          padding: 14px 10px 14px 24px;
          background-color: var(--background-table-header) !important;
          border-radius: 8px;
          > th {
            color: var(--color-label-3);
            background-color: var(--background-table-header) !important;
            font-size: 14px !important;
            font-weight: 400;
            opacity: 0.8;
            &:nth-child(2) {
              padding-left: 40px;
            }
            &:nth-child(3) {
              padding-left: 35px;
            }
            &:nth-child(4) {
              padding-left: 15px;
            }
            &:nth-child(5) {
              padding-left: 25px;
              text-align: center;
            }
          }
        }
      }

      > tbody {
        .row-content {
          &:hover {
            background-color: initial;
          }
          > td {
            &:nth-child(5) {
              padding-left: 28px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  // .table-content::-webkit-scrollbar-thumb {
  //   background: var(--bg-scroll-thumb);
  //   border-radius: 10px;
  // }

  // .table-content::-webkit-scrollbar-track {
  //   background: var(--bg-scroll-track);
  //   border-radius: 10px;
  // }

  .text-value {
    font-size: 14px;
    color: var(--color-value-1);
    white-space: nowrap;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }

  .filter-row {
    display: flex;
    gap: 40px;
    flex-shrink: 0;
  }
  .filter-reset {
    margin-left: 0px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  .filter-row-responsive {
    display: flex;
    gap: 40px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    width: 1280px;
    @media only screen and (max-width: 1600px) {
      width: 100%;
    }
    .pagination-custom {
      > ul {
        > li {
          > button {
            color: var(--color-label-4);
            font-size: 14px;
            font-weight: 500;
            border: 1px solid var(--border-color-pagination);
            border-radius: 5px;
            font-family: inherit;
          }
        }
      }
    }
    @media only screen and (max-width: 300px) {
      padding-left: 103px;
    }
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    margin-top: 65px;
    margin-bottom: 65px;
    max-width: 100%;
    left: 40%;

    @media only screen and (max-width: 1860px) {
      left: 50%;
    }
    @media only screen and (max-width: 970px) {
      max-width: 100vw;
    }

    & > img {
      @media only screen and (max-width: 400px) {
        width: 41px;
        height: 50px;
        margin-left: 4px;
      }
    }

    .no-data-desc {
      margin-top: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: var(--color-no-data-desc);
      opacity: 0.8;
    }
  }
  @media only screen and (max-width: 970px) {
    .row {
      .table-value {
        font-size: 14px !important;
      }
    }
    .tab-details-active {
      font-size: 20px !important;
    }
    .btn-reset {
      font-size: 14px !important;
      height: 44px !important;
    }
    .no-data {
      margin-bottom: 65px;
      margin-top: 65px;
      width: auto;
    }
    .loading-box {
      width: auto;
    }
    .content-details {
      width: 100%;
      overflow: auto;
      .table-header {
        padding-right: 0px;
      }
    }
    .filter-area {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
    }
    .filter-reset {
      justify-content: flex-start;
    }
    .filter-row-responsive {
      display: flex;
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
      gap: 10px;
      > :first-child {
        width: 50%;
      }
      > :last-child {
        width: 50%;
      }
    }
    .btn-reset {
      margin-left: 0px !important;
    }
    .filter-reset {
      margin-left: 0px;
    }
    .filter-date {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    .open-order-details {
      margin-left: 16px;
      margin-right: 16px;
    }
    .pagination {
      margin: 20px 0;
    }
    .filter-date-responsive {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      > div {
        width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 280px) {
    .row {
      display: flex;
      font-size: 18px;
      color: var(--color-value-1);
      > td {
        width: 100%;
      }
      .describe {
        color: var(--color-label-2);
        margin-top: 6px;
      }
      // width: 2150px;
      .text-center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .table-value {
        font-size: 14px !important;
        color: var(--color-value-1);
        &:nth-child(1) {
          width: 180px !important;
          margin-right: 7px !important;
        }
        &:nth-child(2) {
          width: 180px !important;
          margin-right: 12px !important;
        }
        &:nth-child(3) {
          width: 180px !important;
          margin-right: 9px !important;
        }
        &:nth-child(4) {
          width: 180px !important;
          margin-right: 12px !important;
        }
        &:nth-child(5) {
          width: auto;
        }
      }
      .value-buy {
        color: #00b277;
      }
      .value-sell {
        color: #e02d3c;
      }
      .status {
        position: relative;
      }
    }
  }
}
