:root {
  .wrapper-title {
    @media only screen and (max-width: 400px) {
      padding: 16px 16px 0px 14px !important;
    }
  }
  .dialog-title {
    text-align: start;
    font-weight: 600;
    font-size: 34px;
    color: var(--color-title-dialog);
  }
  .content-wrapper {
    color: var(--body-text);
  }
  .margin-top-bottom-9 {
    padding: 6px 0;
    margin-top: 7px;
    color: var(--color-modal-desc) !important;
  }
  .text-label {
    font-size: 18px;
    color: var(--color-label-4);
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }
  .wrap-addable {
    display: flex;
    gap: 8px;
    @media only screen and (max-width: 280px) {
      flex-wrap: wrap;
    }
  }
  .text-max {
    font-size: 18px;
    color: var(--color-label-4);
    white-space: nowrap;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .text-type {
    font-size: 18px;
    color: var(--color-tab-item);
    font-weight: 500;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .text-modal {
    font-size: 16px !important;
    color: var(--color-value-1) !important;
  }
  .trigger-amount {
    width: 25%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    .select-wrapper {
      font-size: 18px !important;
    }
    .choose-trigger {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 15px;
      background: var(--color-background-select);
      font-size: 18px !important;
      width: 139px;
      height: 49px;
      border-radius: 8px;
    }
  }
  .wrapper-input {
    .input-group {
      width: 470px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background: var(--color-input-modal);
      .input {
        width: 100%;
        height: 49px;
      }

      input {
        margin-top: 9px;
        background: transparent;
      }
    }
    .max-btn {
      height: 100%;
      padding: 0px 15px;
      font-weight: 400;
      color: #00ddb3;
      cursor: pointer;
      font-size: 18px;
      width: 70px;
      text-align: end;
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
  }
  .message-error {
    color: var(--color-error);
    text-align: end;
    margin-top: 5px;
    font-size: 14px;
  }

  .error-price {
    border: 1px solid var(--color-background-message_error);
  }

  .button-wrapper {
    width: 100%;
    font-size: 18px;
    position: relative;
    margin-top: 26px;
    .confirm-close-btn {
      width: 100%;
      background: var(--color-background-order-form_cross);
      text-transform: unset;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      color: #333333;
      font-family: inherit;
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
    @media only screen and (max-width: 400px) {
      margin-bottom: 15px;
      margin-top: 3px;
    }
  }

  .wrap-value {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 8px;
  }

  .wrap-current {
    margin-top: 17px;
  }

  .disable {
    cursor: not-allowed;
  }

  @media only screen and (max-width: 768px) {
    .dialog-title {
      font-size: 20px;
      text-align: center;
    }
    .trigger-amount {
      width: 100%;
      margin-bottom: 7px;
      .select-wrapper {
        width: 100%;
      }
      .choose-trigger {
        width: 100%;
      }
    }
    .column {
      flex-direction: column;
    }
    .message-error {
      text-align: start;
    }
    .wrapper-input {
      .input-group {
        width: 100%;
      }
    }
    .text-type {
      font-weight: 600;
    }
  }
}
