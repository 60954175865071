:root {
  .modal-content {
    overflow-y: hidden;
    background-color: var(--switch-color);
    @media screen and (max-width: 576px) {
      margin-bottom: 20px !important;
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        background: var(--switch-color) !important;
        border-radius: 20px !important;
        margin: 30px !important;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--bg-scroll-thumb-calulator) !important;
      }
      &::-webkit-scrollbar-track {
        background: var(--bg-scroll-track-calulator) !important;
        margin: 20px !important;
      }
    }
  }
  .wrapper {
    position: relative;
    height: 100%;
    // background: var(--color-title-active-dark);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      padding: 0 20px;
      border-bottom: 1px solid var(--color-line);
      // background: var(--color-bg-btn-error-disable);
      .pair {
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      .choose-market {
        cursor: pointer;
        font-weight: bold;
        align-items: center;
        min-width: 150px;
        padding: 10px 0px;
        border-radius: 4px;
        border: 1px solid var(--border-market);
        color: var(--color-primary);
        font-size: 12px;
      }
      .choose-market-text {
        cursor: pointer;
        font-weight: bold;
        color: var(--color-primary);
      }
      .instr-symbol {
        margin-left: 5px;
        color: var(--text-orderbook);
        font-weight: bold;
      }
      button {
        border-color: var(--color-text-long);
        color: var(--color-text-long);
        text-transform: none;
        padding: 0 5px !important;
        font-size: 13px;
        &.short {
          color: var(--color-text-short);
          border-color: var(--color-text-short);
          margin-left: 5px;
        }
      }
    }
  }
  .account {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid var(--color-line);
    .label {
      font-weight: bold;
      font-size: 16px;
    }
    .item {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
    }
  }
  .order {
    text-align: left;
    padding: 15px 40px 30px 24px;
    background-color: var(--bg-order);
    height: 811px;
    @media only screen and (max-width: 567px) {
      padding: 15px 20px;
      height: auto;
      margin-bottom: 12px;
    }

    .label {
      font-weight: bold;
      font-size: 16px;
      padding-left: 20px;
      color: var(--text-orderbook);
    }
    .command {
      display: flex;
      grid-gap: 10px;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;

      .close {
        width: 145px;
        padding: 14px 0;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-family: inherit;
        border: none;
        border-radius: 8px;
        font-size: 18px;
        color: var(--color-text);
        background: var(--bg-button);
        cursor: pointer;
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
        @media only screen and (max-width: 567px) {
          font-size: 16px;
          line-height: 19px;
        }
        &:hover {
          opacity: 0.8;
        }
      }

      .filter {
        background-color: transparent;
        width: fit-content;
        border: none;
        cursor: pointer;
        position: relative;
        &.open {
          color: var(--color-title-active-dark);
          background: var(--color-connect-wallet-input-border);
        }
      }

      .filter-option {
        position: absolute;
        background: var(--bg-modal-pre);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        right: 0;
        padding: 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;
        gap: 12px;
        top: 58px;
        z-index: 10;
        white-space: nowrap;
        .option-text {
          color: #6d7387;
          font-size: 16px;
          line-height: 19px;
          &:hover {
            color: var(--color-primary-1);
          }
        }
      }

      .buy {
        width: 165px;
        color: var(--color-white);
        background: #00b277;
        font-family: inherit;
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
      }

      .sell {
        width: 165px;
        color: var(--color-white);
        background: #e02d3c;
        font-family: inherit;
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
      }

      button:disabled {
        cursor: not-allowed;
      }
    }
    .type-order {
      display: flex;
      align-items: stretch;
      margin: 16px 0px;
      gap: 16px;
      position: relative;
      .select-stop {
        // flex: 1 0 auto;
        min-width: 100px;
        border: 1px solid var(--color-line);
        color: var(--color-primary) !important;
      }
      .type-item {
        // width: calc(100% / 3);
        // flex: 1 0 auto;
        cursor: pointer;
        padding: 10px 0px;
        text-align: center;
        color: var(--color-type-item);
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        @media only screen and (max-width: 567px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .type-item-stop {
        display: flex;
        gap: 4px;
        cursor: pointer;
        text-overflow: ellipsis;
        color: var(--text-type-item);
      }
      .type-item-active {
        cursor: pointer;
        color: var(--color-primary-1);
        // border-bottom: 1px solid var(--color-connect-wallet-input-border);
      }
      .tooltip {
        position: absolute;
        right: 5px;
        top: 10px;
        cursor: pointer;
      }
      .icon-up {
        transform: rotate(180deg);
      }
    }
    .wrapper-btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 20px 0px;
      .btn-sell {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        background: #e02d3c;
        border-radius: 8px;
        color: var(--color-white);
        padding: 14px 10px;
      }
      .btn-buy {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        background: #00b277;
        border-radius: 8px;
        color: var(--color-white);
        padding: 14px 10px;
      }
    }
    .order-detail {
      height: 100%;
      //   margin: 16px 20px;
      .type-label {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
      }
      .order-detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .icon-calculator {
          cursor: pointer;
        }
      }
      .field-label {
        font-size: 12px;
        color: var(--text-field-label);
      }
      .order-size {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--color-line);
        background-color: #f8f8f8;
        border-radius: 8px;
        margin-bottom: 12px;
      }
      .order-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 12px;
        background-color: var(--bg-order-input);
        border: 1px solid var(--bg-order);
        &:focus-within {
          border: 1px solid #00ddb3;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: var(--color-label-2);
          cursor: pointer;
        }
        .tif-select {
          display: flex;
          align-items: center;
          padding-right: 12px;
          .label {
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-order-label);
            padding-right: 4px;
            white-space: nowrap;
            @media only screen and (max-width: 567px) {
              font-size: 16px;
              line-height: 19px;
            }
          }

          .icon-up {
            transform: rotate(180deg);
            padding-top: 2px;
          }
          .label-active {
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-order-label-active);
            padding-right: 4px;
            white-space: nowrap;
            @media only screen and (max-width: 567px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }

      .callback-rate-container {
        display: flex;
        gap: 8px;
        cursor: pointer;
        .rate {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--bg-order-input);
          border-radius: 8px;
          margin-bottom: 12px;
          width: 59px;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: var(--color-label-4);
          padding: 14px 5px;
          &:hover {
            color: var(--color-text);
            background: var(--bg-button);
          }
        }
      }

      .input {
        width: 90%;
        padding: 5px 0;
        margin-left: 15px;
        font-family: inherit;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-text);
        background-color: var(--bg-order-input);
        @media only screen and (max-width: 567px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      input::placeholder {
        font-family: inherit;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-available);
        @media only screen and (max-width: 567px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .message-error {
        text-align: center;
        color: var(--color-error);
      }
      .cost-mb {
        @media only screen and (max-width: 400px) {
          margin: 20px 0 16px 0 !important;
        }
      }
      .cost {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        .cost-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .label-span {
            color: var(--color-available);
          }

          .value-span {
            color: var(--color-text);
          }
        }
      }
      .order-value {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        color: var(--text-info);
      }
      .order-balance {
        color: var(--text-info);
        display: flex;
        gap: 4px;
        .value-span {
          color: var(--color-text);
          line-height: 16px;
        }
        span:first-child {
          color: var(--color-available);
          font-size: 14px;
          line-height: 16px;
          line-height: 16px;
        }
      }

      .disabled {
        cursor: not-allowed !important;
      }

      .label-checkbox {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-text);
        @media only screen and (max-width: 567px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .reduce-flex {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        .tif {
          display: flex;
          gap: 4px;
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--color-label-2);
            cursor: pointer;
            @media only screen and (max-width: 567px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
          .tif-label {
            font-size: 16px;
            line-height: 19px;
            color: var(--color-label-4);
            @media only screen and (max-width: 567px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
          .tif-select {
            span {
              padding-right: 4px;
              color: var(--color-text);
            }
            img {
              padding-bottom: 2px;
            }
          }
          .icon-up {
            padding-top: 4px;
            transform: rotate(180deg);
          }
          .tif-icon {
            width: 16px;
            padding-bottom: 14px;
            cursor: pointer;
          }
        }
      }
      .container {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-left: 28px;
        margin-bottom: 10px;
      }
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        left: 0px;
        top: 2px;
        height: 19px;
        width: 19px;
        background-color: var(--checkmark);
        border-radius: 2px;
      }
      .container:hover .checkmark {
        // background: #ccc;
      }
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      .container .checkmark:after {
        left: 6.5px;
        top: 2.5px;
        width: 4px;
        height: 10px;
        border: solid black;
        border-width: 0 2.5px 2.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .container input:checked ~ .checkmark {
        background-color: var(--color-primary-1);
      }
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      .last-price {
        display: flex;
        gap: 5px;
        .last-text {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: var(--color-label-4);
          padding-right: 5px;
          @media only screen and (max-width: 567px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .last {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #00ddb3;
        cursor: pointer;
        padding-left: 10px;
        white-space: nowrap;
        @media only screen and (max-width: 567px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .token-symbol {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-label-4);
        padding-right: 15px;
        @media only screen and (max-width: 567px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .change-price {
        position: relative;
        padding-right: 6px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        justify-content: center;

        .arrow-up {
          cursor: pointer;
          user-select: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .arrow-down {
          cursor: pointer;
          user-select: none;
          &:hover {
            opacity: 0.8;
          }
        }
      }

      .bottom-guest {
        display: block;
        margin-top: 60px;
        padding-top: 30px;
        text-align: center;
        border-top: 1px solid var(--line);
        .button {
          width: 100%;
          margin-top: 34px;
          background: var(--bg-primary);
          color: #fff;
          font-weight: bold;
          padding: 6px 24px 7px 24px;
        }
      }
    }
  }
  text-align: left;
  .leverage-head {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
    color: var(--text-leverage);
    font-weight: bold;
    .leverage-label {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .leverage-upto {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .item {
      height: 36px;
      width: 33px;
      text-align: center;
    }
  }
  .btn {
    width: 100%;
  }

  .select-items {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .select-item {
    padding: 16px;
    border: 1px solid #dedede;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    color: var(--color-label-2);
  }

  .select-item-checked {
    padding: 16px;
    border: 1px solid #03ddb3;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    color: var(--color-value-1);
  }

  .title-dialog {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: var(--color-connect-wallet-input-border);
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px !important;
    button {
      padding: 15px 0;
      width: 100%;
      font-style: normal;
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      border: none;
      cursor: pointer;
      color: var(--color-connect-wallet-input-border);
      background-color: var(--color-background-order-form_cross);
      &:hover {
        transition: 0.5s;
        background-color: var(--color-background-button_confirm_hover);
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--color-label-2);
        color: var(--color-background-text_button_confirm);
        background-color: var(--color-background-button_confirm);
      }
    }
  }

  .dialog-wrapper {
    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .title {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-connect-wallet-input-border);
  }

  .error-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    .message {
      margin-left: 12px;
      color: var(--color-background-message_error);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .description {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-connect-wallet-input-border);
  }

  .box-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .dialog-content {
    padding: 0 16px !important;
  }

  .count-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-connect-wallet-input-border);
    user-select: none;
  }

  .description-wrapper {
    margin-bottom: 16px;
    padding: 0 6px;
  }

  .description-item {
    display: flex;
    gap: 12px;
  }

  .dot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot {
    height: 6px;
    width: 6px;
    background-color: var(--color-connect-wallet-input-border);
    border-radius: 50%;
    display: inline-block;
  }
  .limit-flex {
    display: flex;
    justify-content: end;
    margin-top: 14px;
    // margin-bottom: 156px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 15px;
    }
    .limit {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #00ddb3;
      font-family: inherit;
    }
  }
  .limit-container {
    padding: 15px 15px 3px 15px;
    background: var(--bg-modal);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: none;

    .limit-header {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 12px;
      color: var(--color-value-1);
    }

    .limit-detail {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-label-2);
      }

      div {
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: var(--color-value-1);
      }
    }
  }
  .open {
    font-style: normal;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    font-family: inherit;
    cursor: pointer;
    width: 145px;
    padding: 14px 0;
    border: none;
    color: var(--color-connect-wallet-input-border);
    background: var(--color-background-order-form_cross);
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
    @media only screen and (max-width: 567px) {
      font-size: 16px;
      line-height: 19px;
    }
    &:hover {
      background-color: #57fbdc;
    }
  }
  .sign-up {
    width: 100%;
    margin: 16px 0;
    font-weight: 400;
  }

  .login {
    width: 100%;
    color: var(--color-text);
    background: var(--bg-button);
    margin-bottom: 346px;
    font-weight: 400;

    &:hover {
      background-color: #888888;
    }
  }
}
