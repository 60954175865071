:root {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    width: 100%;
    .text-desc {
      margin-top: 20px;
      font-size: 32px;
      font-weight: 700px;
      line-height: 48px;
    }
  }
}
