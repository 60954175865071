:root {
  .OrderbookTrade {
    height: calc(100% - 26px);

    .Orderbook {
      height: 65%;
      padding: 0px 12px 0px 22px;
      @media only screen and (max-width: 300px) {
        padding: 0px 12px 0px 12px;
      }

      .Orderbook-title {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-value-1);
        margin-top: 20px;
        margin-bottom: 12px;
      }

      .Orderbook-option {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 6px;

        .option {
          display: flex;
          align-items: center;
          background-color: var(--bg-filter-order-book);
          border-radius: 6px;
          padding: 6px;

          .icon-wrapper {
            border-radius: 6px;
            height: 20px;
            &:nth-child(2) {
              padding: 0 10px;
            }

            .icon {
              cursor: pointer;
              opacity: 0.3;
            }
          }
        }

        .option-active {
          // background-color: var(--color-bg-option-ob);
          opacity: 1 !important;
        }

        .option-precision {
          border: none !important;
          min-width: 80px;
          font-size: 14px !important;
          margin-right: 0;
        }
      }

      .orderbook-content {
        height: calc(80% - 28px);

        .last_price_change {
          display: flex;
          margin-bottom: 12px;
          margin-top: 12px;

          .icon-change {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 5px;
          }

          .sub {
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            color: var(--color-label-4);
            font-weight: 400;
            margin-left: 8px;
            margin-bottom: 5px;
            position: relative;
          }
          .icon-positive-value {
            path {
              fill: #00b277;
            }
          }
          .icon-negative-value {
            transform: rotate(180deg);
            path {
              fill: #e02d3c;
            }
          }

          .App-positive-value {
            color: #00b277;
          }

          .App-negative-value {
            color: #e02d3c;
          }
        }
      }

      .table-header {
        margin: 2px 0 15px;
        border-radius: 5px;
        display: block;
        width: 100%;
        text-align: left;
        border-spacing: 0px;

        > thead {
          position: relative;
          display: block;
          width: 100%;

          > tr {
            width: 100%;
            display: flex;

            > th {
              flex-basis: 100%;
              flex-grow: 2;
              color: var(--color-label-4);
              font-size: 14px;
              display: block;
              text-align: right;
              padding-right: 10px;
              font-weight: 400;
              white-space: nowrap;
              &:first-child {
                padding-right: 20px;
                @media only screen and (max-width: 700px) {
                  padding-right: 25px;
                  text-align: left;
                }
              }
              &:nth-child(2) {
                @media only screen and (max-width: 700px) {
                  padding-right: 0px;
                }
              }
            }
          }
        }
      }

      .table-wrapper {
        margin: 0px 0px;
        border-radius: 5px;
        display: flex;
        height: calc(50% - 23px);

        .table {
          height: 100%;
          width: 100%;
          text-align: left;
          border-spacing: 0px;
          display: block;

          > thead {
            position: relative;
            display: block;
            width: 100%;

            > tr {
              width: 100%;
              display: flex;

              > th {
                flex-basis: 100%;
                flex-grow: 2;
                color: var(--text-orderbook);
                font-size: 40px;
                display: block;
                text-align: right;
                padding-right: 30px;
                white-space: nowrap;
                &:first-child {
                  @media only screen and (max-width: 400px) {
                    text-align: left;
                  }
                }
              }
            }
          }

          > tbody {
            height: calc(100%);
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            > tr {
              width: 98%;
              display: flex;
              margin-top: 2px;

              > td {
                font-size: 12px;
                height: 18px;
                flex-basis: 100%;
                flex-grow: 2;
                display: flex;
                align-items: center;
                text-align: right;
                padding-right: 24px;

                .row {
                  position: absolute;
                  right: 0;
                  left: 0;
                  display: flex;
                  padding-top: 2px;

                  > span {
                    flex-grow: 1;
                    flex-basis: 0;
                    &:nth-child(1) {
                      text-align: left;
                      @media only screen and (max-width: 400px) {
                        padding-right: 0px;
                      }
                    }
                  }
                  .normal-value {
                    font-size: 14px;
                    color: var(--color-value-1);
                    // &:last-child {
                    //   margin-right: 8px;
                    // }
                  }
                }
              }
            }
          }
        }

        .table-row {
          position: relative;
          height: 18px;
          cursor: pointer;

          .ordertable-overlay-left {
            position: absolute;
            height: 100%;
            left: 0;
          }

          .ordertable-overlay-right {
            position: absolute;
            height: 24px;
            right: 0;
          }
        }

        .row-odd {
          background-color: var(--color-row-odd);
        }
      }

      .table-wrapper-expand {
        height: calc(100% - 36px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    //scroll bar

    .scrollbar::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      // background: var(--color-scrollbar-track-ob);
      background: var(--background-color);
      border-radius: 20px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
      background: var(--color-scrollbar-ob);
      border-radius: 20px;
      height: 30px;
    }

    .scrollbar::-webkit-scrollbar-track {
      // background: var(--color-scrollbar-track-ob);
      background: var(--background-color);
      border-radius: 20px;
    }

    .non-scrollbar {
      overflow: visible !important;
    }

    .Trade {
      height: 30%;
      padding: 0px 12px 0px 22px;
      @media only screen and (max-width: 300px) {
        padding: 0px 12px 0px 12px;
      }

      .table-wrapper {
        margin: 5px 0px;
        border-radius: 5px;
        height: calc(100% - 30px);

        .table {
          display: block;
          height: 100%;
          width: 100%;
          text-align: left;

          > thead {
            position: relative;
            display: block;
            width: 100%;

            > tr {
              width: 100%;
              display: flex;

              > th {
                flex-basis: 100%;
                flex-grow: 2;
                display: block;
                text-align: right;
                padding-right: 22px;
                font-size: 14px;
                font-weight: 400;
                color: var(--color-label-4);
                margin-bottom: 15px;
                white-space: nowrap;
                @media only screen and (max-width: 700px) {
                  padding-right: 0px;
                }

                &:first-child {
                  padding-right: 14px !important;
                  @media only screen and (max-width: 700px) {
                    text-align: left;
                  }
                }
                &:nth-child(2) {
                  @media only screen and (max-width: 700px) {
                    padding-right: 0px;
                  }
                }
                &:last-child {
                  padding-right: 9px;
                  @media only screen and (max-width: 700px) {
                    padding-right: 7px;
                  }
                }
              }
            }
          }

          > tbody {
            height: calc(100% - 18px);
            display: block;
            position: relative;
            width: 100%;
            overflow-y: scroll;

            > tr {
              width: 100%;
              display: flex;
              margin-top: 2px;

              > td {
                font-size: 14px;
                line-height: 18px;
                flex-basis: 100%;
                flex-grow: 2;
                display: block;

                &:nth-child(1) {
                  padding-right: 24px;
                  @media only screen and (max-width: 700px) {
                    padding-right: 0px;
                  }
                }

                &:nth-child(2) {
                  color: var(--color-value-1);
                  text-align: right;
                  margin-right: 6px;
                  @media only screen and (max-width: 700px) {
                    margin-right: 24px;
                  }
                  @media only screen and (max-width: 400px) {
                    margin-right: 24px;
                  }
                }
                &:nth-child(3) {
                  color: var(--color-value-1);
                  text-align: right;
                }
              }
            }
          }
        }

        .table-row {
          position: relative;
          display: flex;
          flex-direction: row;

          .ordertable-overlay {
            position: absolute;
            height: 100%;
            opacity: 0.2;
            right: 0;
          }

          > td {
            flex-grow: 1;
          }
        }

        .row-odd {
          background-color: var(--color-row-odd);
        }
      }
    }

    .Orderbook-head {
      display: flex;
      font-weight: bold;
      padding: 0px;
      border-radius: 5px;
      font-size: 24px;
      // border: 1px solid var(--color-line);
      .icon-change {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
      }
    }

    .Orderbook-head-trades {
      display: flex;
      font-weight: bold;
      padding: 6px 0px 3px 0px;
      border-radius: 5px;
      font-size: 16px;
      color: var(--color-value-1);
    }

    .orderbook-mb10 {
      margin-bottom: 10px;
    }

    .orderbook-mt10 {
      margin-top: 10px;
    }

    .Orderbook-head:nth-child(1) {
      color: var(--color-value-1);
    }

    .line {
      margin: 7px 0px;
      border-bottom: 4px solid var(--border-pair);
    }
  }

  .check-tooltip {
    position: relative;
    &:hover {
      .tooltip-filter {
        display: block;
      }
    }
    .tooltip-filter {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      align-self: center;
      z-index: 10;
      left: -22px;
    }
  }
}
