:root {
  .dialog-title {
    text-align: center;
    font-weight: 700;
    font-size: 34px;
    color: var(--color-value-1);
    font-family: inherit;
  }
  .dialog-desc {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: var(--color-modal-desc);
    font-family: inherit;
  }
  .content-wrapper {
    color: var(--body-text);
    padding-top: 0px;
  }
  .dialog-warning {
    margin-top: 30px;
  }
  .warning-icon {
    margin-bottom: 26px;
  }
  .divider {
    height: 2px;
    background: var(--color-divider);
    margin: 20px 0;
  }
  .margin-top-bottom-9 {
    padding: 6px 0;
    margin-top: 7px;
  }
  .text-modal {
    font-size: 16px !important;
    color: var(--color-value-1) !important;
  }
  .close-short-text {
    color: var(--color-short-price);
    margin-top: 22px;
    font-size: 18px;
  }
  .close-long-text {
    color: var(--color-primary-2);
    margin-top: 22px;
    font-size: 18px;
  }
  .button-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    margin-top: 32px;
    gap: 24px;
    .close-dialog {
      width: 100%;
      color: var(--text-title-input);
      background: var(--bg-button);
      text-transform: unset;
      height: 50px;
      font-size: 18px;
      font-family: inherit;
      border-radius: 8px;
    }

    .confirm-close-btn {
      width: 100%;
      background: var(--color-background-order-form_cross);
      text-transform: unset;
      height: 50px;
      font-size: 18px;
      font-family: inherit;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    .dialog-title {
      font-size: 24px;
      line-height: 32px;
    }
    .dialog-desc {
      font-size: 16px;
      line-height: 21px;
    }
    .button-wrapper {
      .close-dialog {
        font-size: 16px;
      }
      .close-short-btn {
        font-size: 16px;
        font-weight: 600;
      }
      .close-long-btn {
        font-size: 16px;
        font-weight: 600;
      }
      .confirm-close-btn {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .dialog-title-order {
      font-size: 20px !important;
      text-align: center;
    }
    .text-modal-order {
      font-size: 16px !important;
    }
    .close-short-text,
    .close-long-text {
      font-size: 16px !important;
    }

    .text-left-order,
    .text-right-order {
      font-size: 16px !important;
    }
    .dialog-warning {
      font-size: 14px !important;
    }

    .content-order {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .close-dialog-order {
      font-size: 16px !important;
    }
    .button-wrapper-order {
      .close-short-btn {
        font-size: 16px !important;
      }
      .close-long-btn {
        font-size: 16px !important;
      }
    }
  }

  .content-order {
    padding-left: 40px;
    padding-right: 40px;
  }

  .icon-close {
    text-align: right;
  }

  .dialog-title-order {
    font-size: 34px;
    font-weight: 700;
    font-family: inherit;
    color: var(--color-value-1);
    width: 100%;
  }

  .text-modal-order {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-value-1) !important;
    margin-top: 22px;
  }

  .text-left-order {
    font-size: 18px;
    color: var(--color-date-label);
  }

  .text-right-order {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-tab-item);
  }

  .close-dialog-order {
    width: 100%;
    color: var(--text-title-input);
    background: var(--bg-button);
    text-transform: unset;
    height: 50px;
    font-size: 18px;
    border-radius: 8px;
    font-family: inherit;
    &:hover {
      opacity: 0.8;
    }
  }

  .button-wrapper-order {
    width: 100%;
    position: relative;
    gap: 40px;
    margin-top: 22px;
    .close-short-btn {
      width: 100%;
      color: var(--color-white);
      background-color: var(--color-short-price);
      text-transform: unset;
      height: 50px;
      font-size: 18px;
      border-radius: 8px;
      font-family: inherit;
      &:hover {
        opacity: 0.8;
      }
    }
    .close-long-btn {
      width: 100%;
      color: var(--color-white);
      background-color: var(--color-primary-2);
      text-transform: unset;
      height: 50px;
      font-size: 18px;
      border-radius: 8px;
      font-family: inherit;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .mt-16 {
    margin-top: 16px;
  }
}
