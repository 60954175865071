:root {
  .modal-paper {
    background-color: var(--bg-modal);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: 100;
    .modal-item {
      cursor: pointer;
      padding: 10px 25px;
      font-size: 16px;
      line-height: 19px;
      color: var(--bg-modal-item);

      &:hover {
        color: var(--color-primary-1);
      }
      @media only screen and (max-width: 567px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .active {
      color: var(--modal-item-active);
    }
  }
}
