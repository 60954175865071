:root {
  .tbl_data {
    // max-height: 400px;
    // min-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .no-orders {
      padding: 10px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 230px;
      color: var(--color-label-4);
      font-size: 16px;
    }

    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      justify-content: center;
      margin-top: 65px;
      max-width: 100%;
      @media only screen and (max-width: 970px) {
        max-width: 100vw;
      }

      // & > img {
      //   @media only screen and (max-width: 400px) {
      //     width: 41px;
      //     height: 50px;
      //     margin-left: 4px;
      //   }
      // }

      .no-data-desc {
        margin-top: 16px;
        font-size: 16px;
        color: var(--color-label-4);
        width: 100%;
        text-align: center;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th,
    td {
      //text-align: left;
      padding: 12px 12px 12px 0;
      @media screen and (max-width: 1090px) {
        //padding: 14px 0 8px 25px;
      }
    }

    th {
      color: var(--color-label-4);
      font-weight: 400;
      font-size: 14px !important;
    }

    td {
      color: #333333;
      font-size: 14px !important;
    }

    td {
      .td-viewmore {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        color: var(--text-primary);
        /* identical to box height, or 18px */
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    td {
      button {
        background: none;
        border: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    // tr {
    //   &:hover {
    //     .symbol-order {
    //       .text-value,
    //       .text-perpetual {
    //         color: var(--color-primary-1) !important;
    //       }
    //     }
    //     // .column-symbol {
    //     //   .text-coin,
    //     //   .text-perpetual {
    //     //     color: var(--color-primary-1) !important;
    //     //   }
    //     // }
    //   }
    // }
  }

  thead tr {
    position: sticky;
    &:hover {
      background-color: unset;
    }
  }

  tbody tr.no-color {
    &:hover {
      background-color: unset;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .symbol-order {
    cursor: pointer;
  }

  .warning-table {
    color: var(--color-label-6);
    strong {
      cursor: pointer;
      color: var(--color-primary-1);
    }
  }

  .table-asset {
    margin-bottom: 48px;
  }

  .button-confirm-dialog {
    background-color: var(--color-primary-1) !important;
    color: #fff !important;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    padding: 7px 24px 8px;
    text-transform: none;
  }

  .App-positive-value {
    color: var(--color-primary-1) !important;
  }
  .App-negative-value {
    color: var(--color-short-price) !important;
  }

  .color-buy {
    color: var(--color-primary-2) !important;
  }

  .color-sell {
    color: var(--color-short-price) !important;
  }

  .column-symbol {
    display: flex;
    align-items: center;
    cursor: pointer;
    .text-leverage {
      background: var(--text-leverage);
      border-radius: 4px;
      width: 39px;
      height: 24px;
      color: var(--color-value-1);
      padding: 4px 8px;
      margin-left: 4px;
      font-size: 12px;
    }
    .column-color {
      width: 4px;
      height: 44px;
      margin-right: 8px;
    }
    .App-positive-value {
      background: var(--color-primary-1) !important;
    }
    .App-negative-value {
      background: var(--color-short-price) !important;
    }
  }

  .type-cancel-order {
    width: calc(100% / 2);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 0 0 auto;
    cursor: pointer;
    text-align: center;
    text-overflow: ellipsis;
    .icon-down {
      margin-left: 5px;
    }
    .rotate {
      transform: rotate(3.142rad);
    }
  }

  .container {
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 0px;
    }
    .tab-wrapper {
      display: flex;
      align-items: center;
      padding-left: 39px;
      margin: 10px 0;
      @media only screen and (max-width: 768px) {
        padding-left: 25px;
      }
      .tab {
        padding: 6.5px 10px;
        //width: 15%;
        cursor: pointer;
        color: var(--color-label-4);
        font-size: 14px;
        white-space: nowrap;
      }
      .active {
        border-radius: 4px;
        color: var(--text-tab-active);
        background-color: var(--bg-btn-time);
      }
    }
  }

  .search-btn {
    width: 73px;
    height: 35px;
    border-radius: 8px;
    margin: 0 15px;
    border: none;
    background: var(--color-background-order-form_cross);
    cursor: pointer;
  }

  .reset-btn {
    width: 73px;
    height: 35px;
    border-radius: 8px;
    margin: 0 15px;
    border: none;
    background: var(--color-background-order-form_cross);
    cursor: pointer;
    font-size: 16px;
    font-family: inherit;
    color: #333333;
    &:hover {
      background-color: #57fbdc;
    }
  }

  .input {
    margin-left: 10px;
    font-size: 12px;
    width: 64px;
    height: 24px;
    background: #dedede;
  }
  .input-wrapper {
    width: 100px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0px 56px 0px;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
    }
    .pagination-custom {
      // height: 100px;
      > ul {
        > li {
          > button {
            color: var(--color-label-4);
            font-size: 14px;
            font-weight: 500;
            border: 1px solid var(--border-color-pagination);
            border-radius: 5px;
            font-family: inherit;
          }
        }
      }
    }
  }

  .input-time {
    padding: 5px 16px;
    @media only screen and (max-width: 400px) {
      padding: 5px 9px 5px 16px;
    }
    input {
      font-size: 16px !important;
      color: var(color-date-label) !important;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  .table-head {
    > th {
      font-size: 14px !important;
      color: var(--color-label-4);
      font-weight: 400;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
  }

  .text-coin {
    font-size: 14px;
    color: var(--color-value-1);
    font-weight: 600;
  }

  .text-perpetual {
    font-size: 14px;
    color: var(--color-label-4);
    @media only screen and (max-width: 400px) {
      font-size: 12px;
    }
  }

  .text-value {
    font-size: 14px !important;
    color: var(--color-value-1) !important;
  }

  .text-liq {
    font-size: 14px !important;
    color: #f7931a !important;
  }

  .text-margin {
    color: var(--color-label-4);
  }

  .text-position {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #00ddb3;
    font-family: inherit;
    &:nth-child(1) {
      margin-right: 6px;
    }
    &:nth-child(2) {
      margin: 0px 6px;
    }
  }

  .input-positions {
    color: var(--color-value-1);
    background: var(--color-input-position) !important;
    font-size: 12px;
    font-weight: 600;
    width: 64px;
    height: 24px;
    background: #dedede;
    margin-left: 10px;
    text-align: center;
    padding-left: 0 !important;
  }

  .error-input {
    border: 1px solid var(--color-background-message_error);
  }

  .text-tpsl {
    display: flex;
    margin-right: 4px;
    color: var(--color-value-1);
  }

  .btn-view {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  .btn-edit {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  .table-content {
    padding-left: 40px;
    margin-right: 20px;
    overflow: auto;
    @media only screen and (max-width: 768px) {
      padding-left: 25px;
      margin-right: 12px;
    }
  }

  .btn-underline {
    text-decoration: underline;
  }

  .text-time {
    color: var(--color-label-4);
    padding: 6.5px 10px;
    margin-right: 40px;
  }

  .flex {
    display: flex;
  }

  .tif-icon {
    width: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .transfer {
    color: var(--color-primary-1);
    cursor: pointer;
  }
  .asset {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
