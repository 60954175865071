:root {
  .high-chart-container {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    border-radius: 4px;
  }

  .highcharts-credits {
    visibility: hidden !important;
  }
}
