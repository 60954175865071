:root {
  .wrapper {
    position: relative;
    height: 100%;
    // background: var(--color-title-active-dark);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      padding: 0 20px;
      border-bottom: 1px solid var(--color-line);
      // background: var(--color-bg-btn-error-disable);
      .pair {
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      .choose-market {
        cursor: pointer;
        font-weight: bold;
        align-items: center;
        min-width: 150px;
        padding: 10px 0px;
        border-radius: 4px;
        border: 1px solid var(--border-market);
        color: var(--color-primary);
        font-size: 12px;
      }
      .choose-market-text {
        cursor: pointer;
        font-weight: bold;
        color: var(--color-primary);
      }
      .instr-symbol {
        margin-left: 5px;
        color: var(--text-orderbook);
        font-weight: bold;
      }
      button {
        border-color: var(--color-text-long);
        color: var(--color-text-long);
        text-transform: none;
        padding: 0 5px !important;
        font-size: 13px;
        &.short {
          color: var(--color-text-short);
          border-color: var(--color-text-short);
          margin-left: 5px;
        }
      }
    }
  }
  .account {
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid var(--color-line);
    .label {
      font-weight: bold;
      font-size: 16px;
    }
    .item {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
    }
  }
  .order {
    text-align: left;
    padding: 20px 15px;
    .label {
      font-weight: bold;
      font-size: 16px;
      padding-left: 20px;
      color: var(--text-orderbook);
    }
    .command {
      display: flex;
      grid-gap: 10px;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;

      .open {
        font-style: normal;
        border-radius: 8px;
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;
        width: 145px;
        padding: 14px 0;
        border: none;
        color: var(--color-connect-wallet-input-border);
        background: var(--color-background-order-form_cross);
      }

      .close {
        width: 145px;
        padding: 14px 0;
        border: none;
        font-style: normal;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        font-size: 18px;
        color: var(--color-connect-wallet-input-border);
        background: var(--color-background-order-form_close);
        cursor: pointer;
      }

      .filter {
        background-color: var(--color-background-order-form_filter);
        width: fit-content;
        border: none;
        cursor: pointer;
        &.open {
          color: var(--color-title-active-dark);
          background: var(--color-connect-wallet-input-border);
        }
      }
    }
    .type-order {
      display: flex;
      align-items: stretch;
      margin: 16px 0px;
      .select-stop {
        flex: 1 0 auto;
        min-width: 100px;
        border: 1px solid var(--color-line);
        color: var(--color-primary) !important;
      }
      .type-item {
        width: calc(100% / 3);
        flex: 1 0 auto;
        cursor: pointer;
        padding: 10px 0px;
        text-align: center;
        color: var(--color-value-default);
      }
      .type-item-stop {
        width: calc(100% / 3);
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex: 0 0 auto;
        cursor: pointer;
        padding: 10px 0px;
        text-align: center;
        text-overflow: ellipsis;
        color: var(--text-type-item);
        .icon-down {
          // margin-left: 15px;
        }
      }
      .type-item-active {
        cursor: pointer;
        color: var(--color-connect-wallet-input-border) !important;
        border-bottom: 1px solid var(--color-connect-wallet-input-border);
      }
    }
    .wrapper-btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 20px 0px;
      .btn-sell {
        font-weight: bold;
        border-radius: 5px;
        background-color: var(--color-bg-btn-error);
        color: var(--color-error);
        border: 1px solid var(--color-error);
      }
      .btn-buy {
        font-weight: bold;
        border-radius: 5px;
        background-color: var(--color-bg-btn-success);
        color: var(--color-success);
        border: 1px solid var(--color-success);
      }
    }
    .order-detail {
      height: 100%;
      margin: 16px 20px;
      .type-label {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
      }
      .order-detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .icon-calculator {
          cursor: pointer;
        }
      }
      .field-label {
        font-size: 12px;
        color: var(--text-field-label);
      }
      .order-size {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--color-line);
        border-radius: 4px;
      }
      .order-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--color-line);
        border-radius: 4px;
      }
      .message-error {
        text-align: center;
        color: var(--color-error);
      }
      .cost {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        color: var(--text-info);
        .cost-item {
          display: flex;
          flex-direction: row;
        }
      }
      .order-value {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        color: var(--text-info);
      }
      .order-balance {
        color: var(--text-info);
        display: flex;
        justify-content: space-between;
      }
      .input {
        width: 130px;
        text-align: end;
      }
      .trigger-price {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--line);
        border-radius: 4px;
        .select-wrapper {
          width: 210px;
        }
        .choose-trigger {
          // border: 1px solid var(--line);
          border-radius: 4px;
        }
      }
      .order-option {
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        input {
          background-color: red;
        }
        .label-checkbox {
          align-self: center;
          margin-left: 10px;
          color: var(--text-field-label);
        }
        .post-only {
          display: flex;
          cursor: pointer;
          padding: 2px 6px 2px 2px;
        }
        .hidden {
          display: flex;
          cursor: pointer;
          padding: 2px 6px 2px 2px;
        }
        .disabled {
          cursor: not-allowed;
          pointer-events: none;
          background-color: var(--btn-border-secondary-disabled);
          border-radius: 2px;
        }
      }
      .token-symbol {
        height: 34px;
        line-height: 34px;
        font-weight: bold;
        width: 60px;
        text-align: center;
        color: var(--color-primary);
        background: var(--background-color);
        border-radius: 4px;
      }
      .bottom {
        position: absolute;
        bottom: 10px;
        right: 20px;
        left: 20px;
      }
      .bottom-guest {
        display: block;
        margin-top: 60px;
        padding-top: 30px;
        text-align: center;
        border-top: 1px solid var(--line);
        .button {
          width: 100%;
          margin-top: 34px;
          background: var(--bg-primary);
          color: #fff;
          font-weight: bold;
          padding: 6px 24px 7px 24px;
        }
      }
    }
  }
  text-align: left;
  .leverage-head {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
    color: var(--text-leverage);
    font-weight: bold;
    .leverage-label {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .leverage-upto {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .item {
      height: 36px;
      width: 33px;
      text-align: center;
    }
  }
  .btn {
    width: 100%;
  }

  .select-items {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .select-item {
    padding: 16px;
    border: 1px solid #dedede;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    color: #888888;
  }

  .select-item-checked {
    padding: 16px;
    border: 1px solid #03ddb3;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    color: #333333;
  }

  .title-dialog {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: var(--text-tab-active);
    @media only screen and (max-width: 567px) {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px !important;
    button {
      padding: 15px 0;
      width: 100%;
      font-style: normal;
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      border: none;
      cursor: pointer;
      color: var(--color-connect-wallet-input-border);
      background-color: var(--color-background-order-form_cross);

      @media only screen and (max-width: 567px) {
        font-size: 16px;
        line-height: 19px;
      }

      &:hover {
        transition: 0.5s;
        background-color: var(--color-background-button_confirm_hover);
      }

      &:disabled {
        cursor: not-allowed;
        color: #888888;
        color: var(--color-background-text_button_confirm);
        background-color: var(--color-background-button_confirm);
      }
    }
  }
  .button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      padding: 15px 0;
      width: 100%;
      font-style: normal;
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      border: none;
      cursor: pointer;
      color: var(--color-connect-wallet-input-border);
      background-color: var(--color-background-order-form_cross);
      &:hover {
        transition: 0.5s;
        background-color: var(--color-background-button_confirm_hover);
      }

      &:disabled {
        cursor: not-allowed;
        color: #888888;
        color: var(--color-background-text_button_confirm);
        background-color: var(--color-background-button_confirm);
      }
    }
  }

  .dialog-wrapper {
    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: var(--bg-modal-pre);
    @media only screen and (max-width: 567px) {
      padding: 14px 0px 22px 0px;
    }
  }

  .title {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-connect-wallet-input-border);
  }

  .error-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 22px;

    .message {
      margin-left: 12px;
      color: var(--color-background-message_error);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      @media only screen and (max-width: 567px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .description {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-connect-wallet-input-border);
  }

  .box-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background-color: var(--bg-cal-range);
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .dialog-content {
    padding: 0 16px !important;
  }

  .count-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--text-tab-active);
    user-select: none;
    height: 20px;
    @media only screen and (max-width: 567px) {
      font-size: 14px;
      line-height: 16px;
      img {
        width: 11.66px;
      }
    }
  }

  .count-input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--text-tab-active);
    user-select: none;
    height: 20px;
    @media only screen and (max-width: 567px) {
      font-size: 14px;
      line-height: 16px;
      img {
        width: 11px;
      }
    }

    .input {
      background-color: var(--bg-cal-range);
      font-size: 18px;
      line-height: 21px;
      color: var(--text-tab-active);
      text-align: right;
      padding-left: 0;
      @media only screen and (max-width: 567px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .w-35 {
      width: 35px;
    }
    .w-20 {
      width: 20px;
    }
    .w-15 {
      width: 15px;
    }
  }

  .text-note-cal {
    color: var(--text-tab-active);
  }

  .description-wrapper {
    margin-bottom: 16px;
    padding: 0 6px;
  }

  .description-item {
    display: flex;
    gap: 12px;
  }

  .dot-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 5px;
  }

  .dot {
    height: 6px;
    width: 6px;
    background-color: var(--text-tab-active);
    border-radius: 50%;
    display: inline-block;
  }

  .text-note {
    color: var(--text-tab-active);
    font-size: 16px;
    line-height: 19px;
    @media only screen and (max-width: 567px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
