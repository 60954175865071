:root {
  .wrapper {
    width: 818px;
    z-index: 3;
    background-color: var(--switch-color);

    .tab-wrapper {
      display: flex;
      justify-content: space-between;
      background: var(--border-pre);
      align-items: center;
      .tab {
        margin: 4px 0 0 4px;
        padding: 14px;
        cursor: pointer;
        color: var(--color-label-4);
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }

      .active {
        border-radius: 5px 5px 0 0;
        border-bottom: none;
        color: var(--text-item-select-header);
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        background-color: var(--switch-color);
      }

      .search-wrapper {
        height: 36px;
        position: relative;
        margin: 10px 30px 10px 10px;
        .input-search {
          background: var(--switch-color);
          border: 1px solid var(--border-pre);
          box-sizing: border-box;
          border-radius: 8px;
          position: relative;
          font-size: 14px;
          padding-left: 36px;
          width: 100%;
          height: 100%;
          color: var(--text-color);
          &::placeholder {
            color: var(--text-place-holder-search);
          }
          &:focus {
            outline: none;
          }
        }

        .search-icon {
          width: 16px;
          height: 16px;
          position: absolute;
          left: 10px;
          top: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  .list-instr {
    margin: 10px 0px;
    height: calc(100% - 20px);
    overflow: auto;
    padding: 6px 10px 10px 10px;
    background-color: var(--switch-color);

    .table-wrapper {
      margin: 0px 0px;
      border-radius: 5px;
      height: calc(50% - 23px);
      display: flex;

      .table {
        height: 100%;
        width: 100%;
        text-align: left;
        border-spacing: 0px;
        display: block;

        > thead {
          position: relative;
          display: block;
          width: 100%;

          > tr {
            width: 100%;
            display: flex;

            > th {
              flex-basis: 100%;
              flex-grow: 2;
              display: block;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: var(--text-tab);
              padding: 10px;
            }
          }
        }

        > tbody {
          height: calc(100%);
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;

          > tr {
            width: 100%;
            display: flex;
            margin-top: 2px;

            > td {
              flex-basis: 100%;
              flex-grow: 2;
              display: flex;
              align-items: center;
              text-align: right;
              font-weight: 400;
              font-size: 16px;
              line-height: 21px;
              color: var(--text-item-select-header);
            }
          }
        }

        .sort-table {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .sort-icon {
            display: flex;
            flex-direction: column;
            gap: 3px;
          }
          img {
            cursor: pointer;
          }

          &:nth-child(2) {
            padding-left: 90px;
          }
          &:last-child {
            justify-content: end;
            margin-right: 25px;
          }
        }
      }

      .table-row {
        position: relative;
        height: 21px;
        padding: 10px;
        cursor: pointer;

        .ordertable-overlay-left {
          position: absolute;
          height: 100%;
          left: 0;
        }

        .ordertable-overlay-right {
          position: absolute;
          height: 100%;
          right: 0;
        }
        &:hover {
          background-color: var(--bg-search-market);
        }

        > td {
          &:last-child {
            justify-content: end;
          }
        }
      }

      .no-data {
        text-align: center;
        height: 100%;
        padding-top: 80px;
      }
    }
  }
  .overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 340px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .symbol-pair {
    white-space: nowrap;
  }

  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: var(--background-color);
    border-radius: 20px;
    margin-left: 20px !important;
  }

  .data-table {
    height: 310px !important;
  }

  .text-no-data {
    color: var(--text-tab);
    margin-top: 16px;
  }

  .increase {
    color: #00b277;
  }

  .decrease {
    color: #e02d3c;
  }

  .contract-list {
    min-width: 250px !important;
  }

  .change-time {
    padding-left: 14px;
  }

  .volume {
    margin-right: 40px;
  }
}
