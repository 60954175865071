:root {
  .wrapper-input {
    display: flex;
    align-items: center;
    background: var(--bg-date-picker);
    border-radius: 8px;
    width: 100%;
  }
  .input-time {
    width: 95px;
    border: none;
    outline: none;
    background: var(--bg-date-picker);
    margin-top: 2px;
    font-size: 18px;
    color: var(--color-label-4);
    cursor: pointer;
    padding: 0 !important;
    @media only screen and (max-width: 450px) {
      width: 95px !important;
    }
    @media only screen and (max-width: 400px) {
      width: 78px !important;
    }
  }

  .text-middle {
    font-size: 18px;
    color: var(--color-text-middle);
    padding: 0 6px 0 6px;
    @media only screen and (max-width: 450px) {
      padding: 0;
    }
    @media only screen and (max-width: 400px) {
      padding: 0 4px 0 4px;
      font-size: 14px;
    }
  }

  .calendar-icon {
    position: relative;
    margin-top: 2px;
    .input-ghost {
      width: 16px;
      height: 18px;
      border-radius: 5px;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 280px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
    }

    .input-time {
      width: 70px;
      border: none;
      outline: none;
      background: var(--background-table-header);
      font-size: 18px;
      color: var(--color-place-holder);
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
