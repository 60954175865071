:root {
  tbody tr.no-color {
    td {
      padding: 12px 0;
    }
    &:hover {
      background-color: unset;
    }
  }
  .orders-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .open-order-details {
    margin: 40px 0 120px 40px;
    text-align: start;
    flex: 1;
    display: flex;
    flex-direction: column;
    .tabs-area {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .tab-details {
      font-size: 20px;
      color: var(--color-tab-inactive);
      width: fit-content;
      height: 42px;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: -6px;
      }
    }
    .tab-details-active {
      font-size: 20px;
      color: var(--color-tab-active);
      flex-shrink: 0;
      height: 42px;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #00ddb3;
        border-radius: 20px;
        position: absolute;
        bottom: -6px;
      }
    }
  }
  .loading-box {
    min-height: 253px;
    width: 1280px;
    flex: 1;
    > * div {
      height: 100% !important;
    }
  }
  .filter-reset {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }

  .filter-area {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-direction: row;
    width: 1280px;
    flex-shrink: 0;

    @media only screen and (max-width: 1661px) {
      width: auto;
    }
    .btn-search {
      font-size: 18px;
      color: var(--color-value-1);
      width: 112px;
      height: 49px;
      border-radius: 8px;
      background-color: var(--color-bg-btn-2);
      border: none;
      cursor: pointer;
      user-select: none;
      margin-left: 40px;
    }
    .btn-search:hover {
      opacity: 0.8;
      transition: 0.1s;
    }
    .btn-reset {
      font-size: 18px;
      background-color: #00ddb3;
      width: 112px;
      height: 49px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      user-select: none;
      color: var(--color-value-4);
      font-family: inherit;
      &:hover {
        background-color: #57fbdc;
      }
    }
    .btn-reset:hover {
      opacity: 0.8;
      transition: 0.1s;
    }
    .select-symbol {
      margin-left: 0px;
    }
    .select-type {
      margin-right: 0px;
    }
  }

  .table-content {
    overflow: auto;
    max-width: 78%;
    @media only screen and (max-width: 1860px) {
      max-width: 1280px;
    }
    // &::-webkit-scrollbar-thumb {
    //   background: var(--color-scrollbar) !important;
    // }
    // @media only screen and (max-width: 400px) {
    //   margin-right: 0px;
    // }
    > table {
      margin-bottom: 40px;
      > thead {
        height: 49px;
        > tr {
          padding: 14px 10px 14px 24px;
          background-color: var(--background-table-header) !important;
          border-radius: 8px;
          > th {
            color: var(--color-label-4);
            background-color: var(--background-table-header) !important;
            font-size: 14px !important;
            font-weight: 400;
            opacity: 0.8;
            padding: 12px 10px 12px 10px;
            text-align: left;
            &:nth-child(1) {
              padding-left: 10px;
              padding-right: 90px;
            }
            &:nth-child(2) {
              padding-left: 38px;
              padding-right: 90px;
            }
            &:nth-child(3) {
              padding-left: 58px;
              padding-right: 90px;
            }
            &:nth-child(4) {
              padding-left: 58px;
              padding-right: 90px;
            }
            &:nth-child(5) {
              padding-left: 30px;
              text-align: right;
            }
            &:nth-child(6) {
              padding-left: 97px;
              text-align: right;
            }
            &:nth-child(7) {
              padding-left: 87px;
              text-align: right;
            }
            &:nth-child(8) {
              padding-left: 87px;
              text-align: right;
            }
            &:nth-child(9) {
              padding-left: 60px;
              padding-right: 90px;
            }
            &:nth-child(10) {
              padding-left: 37px;
              padding-right: 90px;
            }
            &:nth-child(11) {
              padding-left: 57px;
              padding-right: 90px;
            }
            &:nth-child(12) {
              padding-left: 86px;
            }
          }
        }
      }

      > tbody {
        .row-content {
          &:hover {
            background-color: initial;
          }

          > td {
            &:nth-child(3) {
              padding-left: 60px;
            }
            &:nth-child(4) {
              padding-left: 60px;
            }
            &:nth-child(5) {
              padding-left: 60px;
            }
            &:nth-child(6) {
              padding-left: 60px;
            }
            &:nth-child(7) {
              padding-left: 60px;
            }
            &:nth-child(8) {
              padding-left: 60px;
            }
            &:nth-child(9) {
              padding-left: 60px;
            }
            &:nth-child(10) {
              padding-left: 60px;
            }
            &:nth-child(11) {
              padding-left: 74px;
            }
            &:nth-child(12) {
              padding-left: 89px;
            }
          }
        }
      }
    }
    .value-buy {
      color: #00b277;
      font-size: 14px;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    .value-sell {
      color: #e02d3c;
      width: auto;
      font-size: 14px;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    .margin-scrollbar {
      margin-bottom: 40px;
    }
  }

  // .table-content::-webkit-scrollbar-thumb {
  //   background: var(--bg-scroll-thumb);
  //   border-radius: 10px;
  // }

  // .table-content::-webkit-scrollbar-track {
  //   background: var(--bg-scroll-track);
  //   border-radius: 10px;
  // }

  .text-value {
    font-size: 14px;
    color: var(--color-value-1);
    white-space: nowrap;
    padding: 0 10px;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
    .describe {
      color: var(--color-label-5);
      @media only screen and (max-width: 400px) {
        font-size: 12px !important;
      }
    }
    .symbol {
      margin-top: 20px;
      margin-left: 30px !important;
      @media only screen and (max-width: 400px) {
        margin-top: 20px;
      }
    }
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 65px;
  max-width: 100%;
  left: 40%;

  @media only screen and (max-width: 1860px) {
    left: 50%;
  }
  @media only screen and (max-width: 970px) {
    max-width: 100vw;
  }

  & > img {
    @media only screen and (max-width: 400px) {
      width: 41px;
      height: 50px;
      margin-left: 4px;
    }
  }

  .no-data-desc {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--color-no-data-desc);
    opacity: 0.8;
  }
}

.filter-status {
  display: flex;
  gap: 6px;
  position: relative;
  cursor: pointer;
  user-select: none;
  .filter-title {
    font-size: 14px;
    color: var(--color-label-2);
  }
  .filter-list {
    background: #ffffff;
    position: absolute;
    padding: 20px 15px 0 15px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 10;
    margin-top: 30px;
    width: 170px;
    height: 210px;
    opacity: 1;
    right: 0;
    .value-list {
      color: var(--color-label-2);
      font-size: 18px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      &:hover {
        color: #00ddb3;
        cursor: pointer;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  width: 1280px;
  @media only screen and (max-width: 1600px) {
    width: 100%;
  }
  .pagination-custom {
    > ul {
      > li {
        > button {
          color: var(--color-value-1);
          font-size: 14px;
          font-weight: 500;
          border: 1px solid var(--border-color-pagination);
          border-radius: 5px;
          font-family: inherit;
        }
      }
    }
  }
}

.type-cancel-order {
  width: calc(100% / 2);
  min-width: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 0 0 auto;
  cursor: pointer;
  text-align: center;
  text-overflow: ellipsis;
  span {
    margin-right: 2px;
  }
  .icon-down {
    margin-left: 5px;
  }
  .rotate {
    transform: rotate(3.142rad);
  }
}

.filter-row {
  display: flex;
  gap: 40px;
  flex-shrink: 0;
  justify-content: space-between;
  > :first-child {
    width: 50%;
  }
  > :last-child {
    width: 50%;
  }
}

.filter-date {
  display: flex;
}

.filter-row-responsive {
  display: flex;
  // margin: 0 40px;
  gap: 40px;
}

.select-side {
  margin-right: 0px;
}

@media only screen and (max-width: 970px) {
  .table-row {
    > td {
      font-size: 14px !important;
    }
  }
  .tab-details-active {
    font-size: 20px !important;
  }
  .btn-reset {
    font-size: 14px !important;
    height: 44px !important;
  }
  .loading-box {
    width: auto;
  }
  .no-data {
    margin-bottom: 65px;
    margin-top: 65px;
  }
  .filter-date-responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    > div {
      width: 100% !important;
    }
  }
  .filter-row-responsive {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-left: 0px;
    margin-right: 0px;
    > :first-child {
      width: 50%;
    }
    > :last-child {
      width: 50%;
    }
  }
  .filter-row {
    display: flex;
    width: 100%;
    gap: 10px;
    > :first-child {
      margin-right: 0px;
    }
    > :last-child {
      width: 50%;
    }
  }
  .filter-area {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    gap: 20px !important;
    .select-symbol {
      margin-left: 0;
      width: 100%;
    }
    .select-type {
      margin-left: 0;
      width: 100%;
      margin-right: 0px;
    }
    .select-side {
      margin-top: 0px;
      margin-right: 0px;
    }
  }
  .open-order-details {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .filter-reset {
    justify-content: flex-start !important;
  }
  .btn-reset {
    margin-left: 0px !important;
  }
  .pagination {
    margin: 20px 0;
    width: auto;
  }
  .select-side {
    width: 100% !important;
  }
  .content-details {
    width: 100% !important;
  }
  .content-details-no-data {
    width: 100% !important;
  }
  .no-data {
    width: auto !important;
  }
}
