:root {
  width: 100%;
  tbody tr.no-color {
    td {
      padding: 12px 0;
    }
    &:hover {
      background-color: unset;
    }
  }
  .orders-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .head-orders {
    width: 100%;
    background: var(--bg-order-title);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0 24px 40px;
    position: relative;
    flex-shrink: 0;
    gap: 2px;
    .head-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      color: var(--color-dialog-bg);
      margin-bottom: 1px;
    }
    .head-label {
      font-size: 34px;
      font-weight: 600;
      line-height: 40px;
      color: var(--color-dialog-bg);
    }
    > img {
      position: absolute;
      right: -30px;
      height: 93px;
      @media only screen and (min-width: 1700px) {
        right: calc(100% - 1420px);
      }
      @media only screen and (max-width: 970px) {
        height: 82px;
      }
    }
  }
  .table-box {
    display: flex;
    width: 100%;
    height: 66px;
  }

  .open-order-details {
    margin: 40px 0 120px 40px;
    text-align: start;
    flex: 1;
    display: flex;
    flex-direction: column;
    .tabs-area {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .tab-details {
      font-size: 20px;
      color: var(--color-tab-inactive);
      width: fit-content;
      height: 42px;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: -6px;
      }
    }
    .tab-details-active {
      font-size: 20px;
      color: var(--color-tab-active);
      flex-shrink: 0;
      height: 42px;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1;
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: #00ddb3;
        border-radius: 20px;
        position: absolute;
        bottom: -6px;
      }
    }
  }
  .loading-box {
    min-height: 253px;
    width: 1280px;
    flex: 1;
    > * div {
      height: 100% !important;
    }
  }
  .content-details-no-data {
    width: 1280px;
    height: auto;
    overflow: hidden;
    flex-shrink: 0;
    .table-header {
      margin: 30px 0 15px;
      border-radius: 5px;
      // display: block;
      width: 100%;
      text-align: left;
      border-spacing: 0px;
      > thead {
        position: relative;
        // display: block;
        width: 100%;
        > tr {
          width: 100%;
          display: flex;
          padding: 14px 0 14px 19px;
          background-color: var(--background-table-header);
          border-radius: 8px;
          > th {
            // flex-basis: 100%;
            flex-grow: 2;
            color: var(--color-label-3);
            font-size: 14px;
            display: block;
            // text-align: right;
            // padding-right: 50px;
            font-weight: 400;
            // opacity: 0.8;
            // min-width: 120px;
            &:nth-child(1) {
              // margin-right: -82px;
              width: 46px;
              margin-left: 24px;
              color: transparent !important;
            }
            &:nth-child(2) {
              width: 180px;
              margin-right: 50px;
            }
            &:nth-child(3) {
              width: 110px;
              margin-right: 50px;
            }
            &:nth-child(4) {
              width: 100px;
              margin-right: 50px;
            }
            &:nth-child(5) {
              width: 68px;
              margin-right: 50px;
            }
            &:nth-child(6) {
              width: 100px;
              margin-right: 50px;
            }
            &:nth-child(7) {
              width: 100px;
              margin-right: 50px;
            }
            &:nth-child(8) {
              width: 100px;
              margin-right: 50px;
            }
            &:nth-child(9) {
              width: 220px;
              margin-right: 50px;
            }
            &:nth-child(10) {
              width: 80px;
              margin-right: 50px;
            }
            &:nth-child(11) {
              width: 170px;
              margin-right: 80px;
            }
            &:last-child {
              cursor: pointer;
              width: 80px;
            }
            > img {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  .table-content {
    overflow: auto;
    margin-right: 16px;
    max-width: 78%;
    @media only screen and (max-width: 1860px) {
      max-width: 1280px;
    }
    // &::-webkit-scrollbar-thumb {
    //   background: var(--color-scrollbar-ob) !important;
    // }
    // &::-webkit-scrollbar {
    //   background: var(--background-color);
    //   border-radius: 20px;
    // }
    // &::-webkit-scrollbar-track {
    //   background: var(--background-color);
    // }
    // @media only screen and (max-width: 400px) {
    //   margin-right: 0px;
    // }
    > table {
      margin-bottom: 40px;
      > thead {
        height: 49px;
        > tr {
          padding: 14px 10px 14px 24px;
          background-color: var(--background-table-header) !important;
          border-radius: 8px;
          > th {
            color: var(--color-label-4);
            background-color: var(--background-table-header) !important;
            font-size: 14px !important;
            font-weight: 400;
            opacity: 0.8;
            &:nth-child(2) {
              padding-left: 40px;
              padding-right: 95px;
            }
            &:nth-child(3) {
              padding-left: 50px;
              padding-right: 95px;
            }
            &:nth-child(4) {
              padding-left: 38px;
              padding-right: 95px;
            }
            &:nth-child(5) {
              padding-left: 80px;
              padding-right: 95px;
            }
            &:nth-child(6) {
              padding-left: 80px;
              text-align: right;
            }
            &:nth-child(7) {
              padding-left: 80px;
              text-align: right;
            }
            &:nth-child(8) {
              padding-left: 80px;
              text-align: right;
            }
            &:nth-child(9) {
              padding-left: 80px;
              padding-right: 95px;
            }
            &:nth-child(10) {
              padding-left: 80px;
            }
            &:nth-child(11) {
              padding-left: 80px;
            }
          }
        }
      }

      > tbody {
        .row-content {
          &:hover {
            background-color: initial;
          }
          > td {
            &:nth-child(2) {
              padding-left: 40px;
            }
            &:nth-child(3) {
              padding-left: 50px;
            }
            &:nth-child(4) {
              padding-left: 40px;
            }
            &:nth-child(5) {
              padding-left: 80px;
            }
            &:nth-child(6) {
              padding-left: 80px;
            }
            &:nth-child(7) {
              padding-left: 80px;
            }
            &:nth-child(8) {
              padding-left: 80px;
            }
            &:nth-child(9) {
              padding-left: 80px;
            }
            &:nth-child(10) {
              padding-left: 80px;
              text-align: center;
            }
            &:nth-child(11) {
              padding-left: 80px;
              text-align: center;
            }
          }
        }
      }
    }
    .value-buy {
      color: #00b277;
      font-size: 14px;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    .value-sell {
      color: #e02d3c;
      width: auto;
      font-size: 14px;
      @media only screen and (max-width: 400px) {
        font-size: 14px !important;
      }
    }
    .margin-scrollbar {
      margin-bottom: 40px;
    }
  }

  // .table-content::-webkit-scrollbar-thumb {
  //   background: var(--bg-scroll-thumb);
  //   border-radius: 10px;
  // }

  // .table-content::-webkit-scrollbar-track {
  //   background: var(--bg-scroll-track);
  //   border-radius: 10px;
  // }

  .text-value {
    font-size: 14px;
    color: var(--color-value-1);
    white-space: nowrap;
    padding: 0 10px;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
    .describe {
      color: var(--color-label-5);
      @media only screen and (max-width: 400px) {
        font-size: 12px !important;
      }
    }
    .symbol {
      margin-top: 20px;
      @media only screen and (max-width: 400px) {
        margin-top: 20px;
      }
    }
  }

  .open-order-filter-and-table-box {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .filter-area {
    display: flex;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    width: 78%;
    flex-shrink: 0;
    @media only screen and (max-width: 1661px) {
      width: auto;
    }
    .btn-reset {
      font-size: 18px;
      color: var(--color-value-4);
      width: 112px;
      height: 49px;
      border-radius: 8px;
      background-color: #00ddb3;
      border: none;
      cursor: pointer;
      user-select: none;
      font-family: inherit;
      &:hover {
        background-color: #57fbdc;
      }
    }
  }
  .table-open-orders {
    // display: block;
    width: 100%;
    // text-align: left;
    // >tbody {
    //     position: relative;
    //     display: block;
    //     width: 100%;
    // }
    .table-row {
      width: 100%;
      display: flex;
      padding: 14px 0 14px 17px;
      .row {
        display: flex;
        // width: 2150px;
        .text-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .table-value {
          font-size: 18px;
          color: var(--color-value-1);
          white-space: nowrap;

          // &:nth-child(1) {
          //   width: 180px;
          //   margin-right: 40px;
          // }
          &:nth-child(2) {
            width: 182px;
            margin-right: 50px;
          }
          &:nth-child(3) {
            width: 116px;
            margin-right: 50px;
          }
          &:nth-child(4) {
            width: 103px;
            margin-right: 48px;
          }
          &:nth-child(5) {
            width: 72px;
            margin-right: 50px;
          }
          &:nth-child(6) {
            width: 104px;
            margin-right: 50px;
          }
          &:nth-child(7) {
            width: 102px;
            margin-right: 50px;
          }
          &:nth-child(8) {
            width: 106px;
            margin-right: 50px;
          }
          &:nth-child(9) {
            width: 222px;
            margin-right: 50px;
          }
          &:nth-child(10) {
            width: 83px;
            margin-right: 50px;
          }
          &:nth-child(11) {
            width: 202px;
            margin-right: 50px;
          }
          &:last-child {
            cursor: pointer;
            width: calc(100% - 1870px);
          }
          .describe {
            color: var(--color-label-5);
          }
        }
        .icon-cancel {
          cursor: pointer;
          margin-right: 50px;
          width: 25px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
        .value-buy {
          width: auto;
          font-size: 18px;
          color: #00b277;
          margin-right: 50px;
          width: 72px;
        }
        .value-sell {
          font-size: 18px;
          color: #e02d3c;
          margin-right: 50px;
          width: 72px;
        }
      }
    }
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    margin-top: 65px;
    max-width: 100%;
    left: 45%;

    @media only screen and (max-width: 1860px) {
      left: 50%;
    }
    @media only screen and (max-width: 970px) {
      max-width: 100vw;
    }

    & > img {
      @media only screen and (max-width: 400px) {
        width: 41px;
        height: 50px;
        margin-left: 4px;
      }
    }

    .no-data-desc {
      margin-top: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: var(--color-no-data-desc);
      opacity: 0.8;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    width: 1280px;
    @media only screen and (max-width: 1600px) {
      width: 100%;
    }
    .pagination-custom {
      > ul {
        > li {
          > button {
            color: var(--color-value-1);
            font-size: 14px;
            font-weight: 500;
            border: 1px solid var(--border-color-pagination);
            border-radius: 5px;
            font-family: inherit;
          }
        }
      }
    }
  }
  .filter-row {
    display: flex;
    gap: 40px;
    flex-shrink: 0;
  }
  .filter-row1 {
    display: flex;
    flex: 1;
    justify-content: space-between;
    @media only screen and (max-width: 1626px) {
      flex: 0;
    }
    > :first-child {
      margin-right: 40px;
    }
  }
  .filter-row-responsive {
    display: flex;
    gap: 40px;
  }
  .filter-row-res {
    display: flex;
    > *:first-child {
      margin-right: 40px;
    }
  }

  .btn-view {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  /* Tablet styles */
  @media only screen and (max-width: 970px) {
    .table-value {
      font-size: 14px !important;
    }
    .head-orders {
      padding: 14px 0px 15px 16px;
    }
    .head-title {
      font-size: 18px !important;
      line-height: 21px !important;
    }
    .head-label {
      font-size: 24px !important;
      line-height: 29px !important;
    }
    .tab-details-active {
      font-size: 20px !important;
    }
    .loading-box {
      width: auto;
    }
    .no-data {
      margin-bottom: 65px;
      margin-top: 65px;
    }
    .btn-reset {
      font-size: 14px !important;
      height: 44px !important;
    }
    .filter-row {
      display: flex;
      width: 100%;
      gap: 10px;
      > :first-child {
        width: 50%;
      }
      > :last-child {
        width: 50%;
      }
    }
    .filter-row-responsive {
      display: flex;
      width: 100%;
      gap: 10px;
      > :first-child {
        width: 50%;
      }
      > :last-child {
        width: 50%;
      }
    }
    .filter-row-res {
      display: flex;
      flex-direction: row;
      width: 100%;
      > :first-child {
        width: 50%;
      }
      > :last-child {
        width: 50%;
        margin-right: 0;
      }
    }
    .filter-area {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .filter-all {
      width: 100%;
    }
    .filter-symbol {
      width: 100%;
    }
    .filter-side {
      display: block;
      width: 100%;
    }
    .filter-reset {
      width: auto;
    }

    .open-order-details {
      margin-left: 16px;
      margin-right: 16px;
    }
    .pagination {
      margin: 20px 0;
      width: auto;
    }
    .content-details {
      width: 100%;
      overflow: scroll;
    }
    .no-data {
      width: auto;
    }
  }
}
