:root {
  .navbar {
    box-shadow: none;
    background-color: var(--bg-navbar);
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid var(--border-nav);
    .navbar-left {
      display: flex;
    }
    .navbar-right {
      display: flex;
    }
    .tool-bar {
      min-height: 64px;
      @media only screen and (max-width: 970px) {
        padding: 0px !important;
        min-height: 60px;
      }
    }
    .tool-bar-orders {
      min-height: 83px;
      padding-left: 260px;
      padding-right: 260px;
      @media only screen and (max-width: 1820px) {
        padding-left: 100px;
        padding-right: 135px;
      }
      @media only screen and (max-width: 970px) {
        min-height: 60px;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
    .menu-navbar {
      position: relative;
      display: none;
      margin-right: 25px;
      cursor: pointer;
      user-select: none;
      border: none;
      height: 100%;
      background-color: var(--bg-btn-menu);
      min-width: 58px;
      -webkit-tap-highlight-color: transparent;

      @media only screen and (max-width: 970px) {
        display: block;
        margin-right: 0px;
      }
      @media only screen and (max-width: 400px) {
        margin-right: 0px;
      }
    }
    .menu-back-drop {
      background-color: rgba($color: #000000, $alpha: 0.5);
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 60px;
      z-index: 9;
    }
    .menu-options {
      top: 60px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      position: fixed;
      height: calc(100vh - 60px);
      width: 275px;
      left: 0;
      background: var(--bg-btn-menu);
      z-index: 99999;
      text-align: left;
      padding: 21px 20px 0 20px;
      overflow: auto;
      @media only screen and (max-width: 300px) {
        width: 240px;
      }
    }
    .open-menu {
      background-color: #00ddb3;
      min-width: 58px;
      min-height: 58px;
    }
    .logo {
      cursor: pointer;
      color: var(--name-pj);
      font-size: 18px;
      align-items: center;
      font-weight: bold;
      margin-right: 70px;
      margin-left: 16px;
      @media only screen and (max-width: 500px) {
        margin-right: 12px;
        margin-left: 8px;
      }
      @media only screen and (max-width: 350px) {
        display: none;
      }
    }
    .logo-mb {
      cursor: pointer;
      display: none;
      color: var(--name-pj);
      font-size: 18px;
      align-items: center;
      font-weight: bold;
      margin-right: 70px;
      @media only screen and (max-width: 400px) {
        margin-right: 20px;
      }
      @media only screen and (max-width: 350px) {
        display: block;
      }
    }
    .navbar-item {
      cursor: pointer;
      display: flex;
      color: var(--text-title-color);
      padding: 10px 5px;
      font-size: 15px;
      .select-wrapper {
        min-width: 114px;
      }
      .header-title {
        color: var(--color-value-1);
        font-size: 16px;
      }
    }
    .choose-trigger {
      width: 95px;
    }
    .choose-trigger-support {
      width: 110px;
    }
    .navbar-item__order {
      display: flex;
      align-items: center;
      cursor: pointer;
      display: flex;
      color: var(--text-title-color);
      font-size: 15px;
    }
    .navbar-item__language {
      min-width: 150px;
      color: var(--navbar-item);
      font-size: 15px;
      border: none;
      margin-left: 10px;
    }
    .navbar-item:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
    .navbar-item-active {
      font-weight: bold;
      cursor: pointer;
      color: var(--color-primary);
      background-color: var(--color-bg-active-route);
      padding: 10px 0px;
      border-radius: 4px;
    }
    .arrow-icon {
      color: var(--arrow-icon);
      font-size: 24px;
    }
    .button {
      text-transform: none;
      color: #000000;
      margin-right: 16px;
    }
    .select-trading-btn {
      min-width: 150px;
      text-align: right;
    }
    .font-weight-bold {
      font-weight: bold;
    }
    .text-color {
      color: var(--title-active);
    }
    .select_theme {
      cursor: pointer;
      margin-right: 16px;
    }
    .bell_item {
      padding: 11px 10px;
      background-color: rgba(62, 123, 250, 0.1);
      border-radius: 4px;
      margin: 0px 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .nav-landing {
      margin: 0px 16px 0px 0px;
      text-align: center;
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      font-weight: 500;
      .white-paper {
        color: var(--color-title-active);
        cursor: pointer;
        margin-right: 30px;
        font-size: 14px;
        line-height: 20px;
      }
      & > button {
        width: 115px;
        height: 30px;
        padding: 10px;
        display: flex;
        align-self: flex-start;
        border-radius: 10px;
        font-size: 12px;
        text-transform: none;
        box-shadow: none;
        line-height: 16px;
        &:focus-visible {
          background-color: red;
        }
      }
      .signin-btn,
      .signin-btn:hover {
        background-color: #fff !important;
        color: var(--primary-button-bg);
        border: 1px solid var(--primary-button-bg);
      }
      .signin-btn {
        margin-right: 16px;
      }
      .register-btn,
      .register-btn:hover {
        background-color: var(--primary-button-bg) !important;
        color: #fff;
      }
    }
    .notification {
      display: flex;
      position: relative;
      div {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: bold;
        background-color: red;
        border-radius: 50%;
        padding: 5px;
        height: 12px;
        font-size: 12px;
        color: white;
        width: 13px;
      }
    }
    .btn-log-in {
      cursor: pointer;
      font-size: 16px;
      color: #00ddb3;
      border: none;
      background: inherit;
      margin-right: 10px;
      white-space: nowrap;
      font-family: 'inter-regular';
      @media only screen and (max-width: 970px) {
        display: none;
      }
    }
    .btn-sign-up {
      font-family: 'inter-regular';
      cursor: pointer;
      border: none;
      background: #00ddb3;
      padding: 8px 10px;
      border-radius: 4px;
      font-size: 16px;
      color: #333333;
      margin-right: 20px;
      white-space: nowrap;
      @media only screen and (max-width: 970px) {
        display: none;
      }
    }
    .wrapper-btn-action {
      text-align: center;
      margin-top: 180px;
    }
    .btn-log-in-mb {
      cursor: pointer;
      font-size: 16px;
      color: #00ddb3;
      border: none;
      background: inherit;
      margin-right: 10px;
      padding: 8px 37px;
      white-space: nowrap;
    }
    .btn-sign-up-mb {
      cursor: pointer;
      border: none;
      background: #00ddb3;
      padding: 8px 37px;
      border-radius: 4px;
      font-size: 16px;
      color: #333333;
      margin-right: 20px;
      white-space: nowrap;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-width: 100px;
    .theme-select__control {
      width: 150px;
    }
    .icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .dropdown-currency {
    max-height: 165px;
    overflow-y: auto;
  }
  .hidden-trade-button {
    visibility: hidden;
  }
  @media only screen and (max-width: 970px) {
    .mr-16 {
      margin-right: 16px;
    }
    .ml-16 {
      margin-left: 16px;
    }
  }

  .text-market {
    color: var(--color-text);
    font-size: 16px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 7px 0px;
    white-space: nowrap;
    margin-right: 16px;
    &:hover {
      color: var(--color-primary-1);
    }
    @media only screen and (max-width: 970px) {
      display: none;
    }
  }

  .text-market-mobile {
    color: var(--color-text);
    font-size: 16px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 6px 0px 6.5px 0px;
    white-space: nowrap;
    font-family: 'inter-regular';
    &:hover {
      color: var(--color-primary-1);
    }
  }
  .line {
    width: 1px;
    height: 16px;
    background-color: #dedede;
    margin-right: 16px;
    @media only screen and (max-width: 970px) {
      display: none;
    }
  }
}
