:root {
  .select-wrapper {
    position: relative;

    > ul {
      & > li::marker {
        content: '';
      }
      list-style-type: none;
    }

    > ul {
      display: inline;
      margin: 0;
      padding: 0;
    }
    > ul li:hover ul {
      display: block;
    }
    .select-title {
      color: var(--color-text);
      font-size: 16px;
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding: 6.5px 0px;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      font-family: 'inter-regular';

      &::marker {
        content: none;
      }

      .icon {
        margin-bottom: 1px;
      }
      &:hover {
        > svg path {
          fill: var(--color-primary-1);
        }
      }
    }

    .open-select {
      color: var(--color-primary-1);
      & > svg {
        transform: rotate(3.142rad);
        path {
          fill: var(--color-primary-1);
        }
      }
    }

    .option {
      display: block;
      min-height: auto;
      background-color: var(--bg-navbar);
      border-radius: 8px;
      padding: 0px 15px 0px 0px;

      > li {
        display: block;
        &:nth-child(2) {
          margin-top: 12px;
        }
      }

      .option-text {
        font-size: 16px;
        color: var(--color-text);
        margin-left: 15px;
        text-align: left;
        white-space: nowrap;
        width: 100%;
        font-family: 'inter-regular';
      }

      .option-value {
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: normal;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &:hover {
          border-radius: 8px;
          background-color: var(--bg-hover-item-select-header);
          .link-icon {
            display: block;
          }
        }

        > a {
          text-decoration: none;
          display: flex;
        }

        .link-icon {
          display: none;
          margin-bottom: 5px;
          position: absolute;
          right: 0;
          margin-right: 15px;
        }
      }
    }

    .ul {
      display: inline;
      margin: 0;
      padding: 0;
    }

    .hover {
      &:hover {
        display: block;
      }
    }
  }

  .icon-option {
    margin-right: 10px;
    width: 15px;
  }

  .icon-option-rotate {
    transform: rotate(3.142rad);
    margin-right: 10px;
    width: 15px;

    path {
      fill: var(--color-primary-1);
    }
  }

  .wrapper-select {
    display: flex;
    align-items: center;
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    // gap: 14px;

    .sub-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .link-icon {
        margin-right: 15px;
        display: none;
      }

      &:hover {
        border-radius: 8px;
        background-color: var(--bg-hover-item-select-header);

        .link-icon {
          display: block;
        }
      }
    }

    .path-select {
      padding: 0px 0px 10px 20px;
      font-size: 16px;
      color: var(--color-text);
      font-family: 'inter-regular';
      &:nth-child(1) {
        margin-top: 12px;
      }

      &:hover {
        color: var(--color-primary-1);
      }
    }
  }
  .select-box-custom {
    margin-bottom: 24px !important;
  }
}
