:root {
  .modal-paper {
    border-radius: 4px !important;
    border: 1px solid var(--color-line);
    background-color: var(--popup-background);
    color: var(--text-body-color);
    padding: 10px 0px;
    font-size: 14px;
    z-index: 1;
    top: 5px !important;
    left: 20px !important;
    min-width: 85px;
    .modal-item {
      cursor: pointer;
      padding: 5px 10px;
      color: var(--color-body);
      &:hover {
        color: #00ddb3;
      }
    }
    .active {
      color: #00ddb3;
    }
  }

  .modal-paper-empty {
    border-radius: 4px !important;
    border: 1px solid var(--color-line);
    background-color: var(--popup-background);
    color: var(--text-body-color);
    padding: 10px 0px;
    font-size: 14px;
    z-index: 1;
    // top: 105px !important;
    // left: 18px !important;
    min-width: 85px;

    .modal-item {
      cursor: pointer;
      padding: 5px 10px;
      color: var(--color-body);

      &:hover {
        color: var(--color-primary-1);
      }
    }

    .active {
      color: var(--color-primary-1);
    }
  }
}
