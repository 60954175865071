:root {
  .dialog-wrapper {
    padding: 40px;
    min-width: 440px;
    background: var(--color-dialog-background);
  }
  .title-dialog {
    color: var(--color-dialog-title);
    font-size: 18px;
    font-weight: 400;
    font-family: inherit;
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
  .title-dialog-transfer {
    color: var(--color-dialog-title);
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 30px;
    @media screen and (max-width: 400px) {
      margin-bottom: 24px;
    }
  }
  .transfer-box {
    background-color: var(--color-transfer-box);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 18px;
    @media screen and (max-width: 300px) {
      padding: 10px;
    }
    .text-transfer {
      color: var(--color-text-transfer);
      width: 45px;
      margin-left: 15px;
      margin-right: 15px;
      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
      @media screen and (max-width: 300px) {
        font-size: 14px;
      }
    }
    .text-label {
      color: var(--color-text-label);
      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
      @media screen and (max-width: 300px) {
        font-size: 14px;
      }
    }
    .arrow-transfer {
      margin: 12px 0;
    }
    .switch-icon {
      cursor: pointer;
    }
  }
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin: 10px 0px;
    background: var(--color-background-input-group) !important;
    color: var(--color-input-group);
    .input {
      width: 100%;
      padding: 8px 0px !important;
    }
    input {
      width: 100%;
      background: transparent;
      color: var(--color-input-group);
    }
    input:focus {
      color: var(--color-input-group);
    }
  }
  .coin-price {
    display: flex;
    color: var(--color-coin-price);
    font-size: 14px;
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
    > *:last-child {
      margin-left: 2px;
    }
  }
  .max-btn {
    height: 100%;
    padding: 0px 15px;
    font-weight: 600;
    color: var(--color-max-btn);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .button-confirm {
    padding: 15px 0;
    width: 100%;
    font-style: normal;
    border-radius: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border: none;
    cursor: pointer;
    color: #333333;
    background-color: var(--color-bg-btn-3);
    &:hover {
      transition: 0.5s;
      opacity: 0.8;
    }
    &:disabled {
      cursor: not-allowed;
      color: var(--color-button-confirm-disabled);
      background-color: var(--color-background-button-confirm);
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
  .dialog-content {
    padding: 0;
  }
  .button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }
  .text-desc {
    color: var(--color-text-desc);
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
  }
  .amount-desc {
    color: var(--color-text-desc);
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
  }
  .arrow-transfer {
    margin: 12px 0;
  }
  .switch-icon {
    cursor: pointer;
  }
  .select-wrapper {
    position: relative;
    width: 100%;
  }
  .select-search {
    background-color: var(--color-background-select-search);
    display: flex;
    gap: 12px;
    padding: 10px 15px;
    border-radius: 8px;
    input {
      color: var(--color-input-select-search);
      border: none;
      background-color: transparent;
      width: 80%;
      font-weight: 400;
      font-size: 18px;
      color: #6d7387;
      &:focus-visible {
        outline: none !important;
      }
    }
    input:focus {
      color: var(--color-input-select-search);
    }
  }
  .select-item {
    font-weight: 400;
    font-size: 18px;
    color: var(--color-input-select-search);
  }
  .coin-value {
    text-align: end;
    color: var(--color-coin-value);
    @media screen and (max-width: 300px) {
      font-size: 16px;
    }
  }
  .wrapper-input {
    margin-top: 10px;
  }
  .wrapper-amount {
    margin-top: 16px;
  }
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 6px;
    width: 100%;
    background: #f8f8f8;
    .input {
      width: calc(100% - 28px);
      padding: 8px 0px !important;
    }
    input {
      width: calc(100% - 28px);
      background: transparent;
    }
    .input-in {
      &::placeholder {
        @media screen and (max-width: 300px) {
          font-size: 16px;
        }
      }
    }
    @media screen and (max-width: 1020px) {
      width: 311px;
    }
  }
  .select-value {
    background-color: var(--color-search-value);
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    gap: 32px;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-tab-inactive);
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .coin-select {
    color: var(--color-coin-select);
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
    @media screen and (max-width: 300px) {
      font-size: 14px;
    }
  }
  .coin-label {
    color: #6d7387;
    font-size: 14px;
  }
  .select-list {
    margin-top: 10px;
    position: absolute;
    background-color: var(--bg-select-list);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 93%;
    padding: 15px;
    z-index: 10000;
    top: 38px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 150px;
    overflow: auto;
    overflow-x: hidden;
    @media screen and (max-width: 300px) {
      width: 84%;
    }
  }
  .max-btn {
    height: 100%;
    padding: 0px 15px;
    font-weight: 600;
    color: #00ddb3;
    cursor: pointer;
    @media screen and (max-width: 400px) {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .close-group {
    cursor: pointer;
    z-index: 99;
    margin-bottom: 2px;
  }
  .message-error {
    color: var(--color-error);
    margin-bottom: 30px;
    @media screen and (max-width: 400px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1023px) {
    .dialog-wrapper {
      padding-top: 24px;
      padding-bottom: 30px;
      padding-left: 16px;
      padding-right: 16px;
      min-width: 0;
    }
    .dialog-content {
      padding: 0;
    }
    .title-dialog-transfer {
      color: var(--color-dialog-title);
      font-size: 20px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 300px) {
    .dialog-wrapper {
      padding: 16px 10px 30px 10px;
      min-width: 0;
    }
  }

  .rotate {
    transform: rotate(3.142rad);
  }
}
