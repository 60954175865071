:root {
  .trading-wrapper {
    height: 100%;
    // height: calc(100% - 300px);
    // max-height: calc(100% - 300px);
    // min-height: calc(100% - 300px);
    .box_container {
      display: flex;
      width: 100%;
      position: relative;
      .statistic-prev {
        left: 0px;
        height: 92%;
        width: 68px;
        margin: 0px;
        left: 0px;
        border-radius: 4px;
        background-image: linear-gradient(-270deg, #fafafc 71.88%, rgba(250, 250, 252, 0) 100%);
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 770px) {
          display: none;
        }
      }
      .statistic-next {
        background-image: var(--color-arrowNext);
        height: 92%;
        width: 68px;
        right: 0px;
        margin: 0px;
        border-radius: 4px;
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 770px) {
          display: none;
        }
      }
    }
  }
}
