:root {
  .wrapper {
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 400px;
    width: 100%;
    height: auto;
    padding: 17px;
    flex-direction: row;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .noti-icon {
    // padding: 15px 17px 0px 17px;
    flex-shrink: 0;
  }
  .noti-content {
    width: 100%;
    flex: 1;
    margin: 0px 17px 0px 17px;
    word-wrap: break-word;
  }

  .noti-btn-close {
    flex-shrink: 0;
    // padding: 10px 17px 0px 17px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }
  .noti-error {
    background: var(--color-select-list);
    border: 1px solid #e02d3c;
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--color-connect-wallet-input-border);
    .content-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      white-space: break-spaces;
      text-align: left;
      width: 100%;
      line-height: 150%;
      color: var(--color-text);
    }

    .content-message {
      margin-top: 8px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-transfer);
      font-style: normal;
      line-height: 150%;
    }
    .content-sub-message {
      margin-top: 8px;
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-transfer);
      line-height: 150%;
    }
  }
  .noti-success,
  .noti-info {
    background: var(--color-select-list);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--color-connect-wallet-input-border);
    // box-shadow: 0.5vmin 0.5vmin 1vmin var(--color-box-shadow-1), -0.5vmin -0.5vmin 1vmin var(--color-box-shadow-2);
    .content-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      white-space: break-spaces;
      text-align: left;
      color: var(--color-text);
    }

    .content-message {
      margin-top: 8px;
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-transfer);
      line-height: 150%;
    }
    .content-sub-message {
      margin-top: 8px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      color: var(--color-text-transfer);
      font-style: normal;
      line-height: 150%;
    }
  }
  // .flex-box-col-start {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   flex-direction: column;
  //   width: 100%;
  // }
  // .flex-box-row-start {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   flex-direction: row;
  //   width: 100%;
  // }
}
