@import 'src/styles/App.module.scss';
:root {
  .icon-option {
    width: 30px;
    height: 20px;
    border-radius: 2px;
    margin-left: 4px;
    margin-right: 8px;
  }

  .custom-option {
    display: flex;
    flex-flow: row;
    align-items: center;

    .icon-option {
      margin-left: 4px;
    }
  }

  .control-field-icon-option {
    margin-left: 16px;
  }

  .search-bar-container {
    margin: 16px 13px 19px 13px;
    position: relative;

    .clear-search-icon-container {
      position: absolute;
      top: 50%;
      right: 5px;
      cursor: pointer;
      transform: translateY(-50%);
      height: 20px;

      > img {
        width: 20px;
        height: 20px;
      }
    }

    .search-bar {
      border-radius: 10px;
      width: 100%;
      border: 1px solid var(--color-line);
      height: 36px;

      input {
        background-color: var(--input-background);
        color: var(--text-color);
        padding: 12px 40px 12px 14px;
        border: none !important;
      }
      &::placeholder {
        color: var(--text-color);
      }
    }

    .search-bar-no-search-icon {
      > div input {
        margin-left: 10px;
      }
    }
  }

  .theme-select-input__control {
    border-radius: 4px !important;
    background: var(--color-select-body) !important;
    min-width: 150px;

    &--is-focused {
      border: 1px solid transparent !important;
      box-shadow: none;
    }
    .theme-select-input__value-container {
      min-height: 36px;
      padding: 0;
    }
    .theme-select-input__multi-value {
      background: var(--color-primary);
      color: var(--color-white);
      border-radius: $space-4;
      height: 26px;
      margin: 5px 5px 5px 0;
      display: flex;
      align-items: center;

      .theme-select-input__multi-value__label {
        color: var(--color-white);
        padding: 5px 6px;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .theme-select-input__multi-value:first-child {
      margin-left: 0px;
    }
    .theme-select-input__multi-value__remove:hover {
      background: #f5f5f5;
      svg {
        color: var(--color-white);
      }
    }
    .theme-select-input__placeholder {
      margin-left: $space-12;
    }
  }

  .theme-select-input__control--is-disabled {
    .theme-select-input__value-container {
      background: #f5f5f5 !important;
    }
    .theme-select-input__multi-value--is-disabled {
      background: var(--color-placeholder);
    }
    .theme-select-input__multi-value__remove {
      background: var(--color-placeholder);
    }
  }

  .theme-select-input__menu {
    z-index: 100 !important;
    margin: 4px 0 !important;
    border-radius: 4px !important;
  }

  .theme-select-input__control:focus {
    outline: none;
    border: none;
  }
  .theme-select-input__control--menu-is-open:focus {
    outline: none;
    border: none;
  }

  // state
  .theme-select-input__value-container:focus {
    outline: none;
  }

  .theme-select-input__value-container--has-value {
    padding-left: 5px !important;
    outline: none !important;
  }
  .theme-select-input__menu-list {
    padding: 0 !important;
    cursor: pointer !important;
  }
  .theme-select-input__option--is-selected {
    background: var(--color-primary) !important;
    border-radius: 4px;
  }
  .css-2b097c-container:focus {
    border: none !important;
    outline: none !important;
  }

  .theme-select-input__indicator-separator {
    background-color: transparent !important;
  }
}
