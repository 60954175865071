.theme-input {
  background: var(--background-color);
  //   color: $theme-placeholder;
  // min-width: 200px;
  width: 100%;
  height: 56px;
  border: 1px solid var(--line);
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--body-text);
  &:focus {
    color: var(--color-value-1);
    outline: none;
    // border: 1px solid var(--color-primary);
  }
  &::placeholder {
    color: var(--placeholder);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
  }
}
.theme-input-lg {
  height: 56px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.theme-input-sm {
  height: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}
.theme-input-xs {
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.pl-input-16 {
  padding-left: 16px;
}

// .theme-input-search {
//   background: #f5f5f5;
// }
.value-error {
  border: 1px solid red;
}
.value-ok {
  border: 1px solid green;
}
.value-warning {
  border: 1px solid orange;
}

.icon-lg {
  width: 20;
  height: 20;
}
.icon-sm {
  width: 16;
  height: 16;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flex {
  display: flex;
}
.items-center {
  justify-content: center;
}
.left-0 {
  left: 0;
}
.pl-12 {
  padding-left: 32px;
}
.top-50 {
  top: 50%;
}
.span-input {
  position: absolute;
  top: 50%;
  transform: translate(60%, -50%);
}
.bg-transparent {
  background: transparent;
  border: none;
  cursor: pointer;
}
.text-red-600 {
  color: red;
}

.input-text-before {
  padding-left: 26px;
}

.text-before {
  width: 17px;
  color: var(--text-color);
  left: 0;
  transform: translate(50%, 10px);
  text-align: right;
}
.no-outline {
  border: 0;
}
