:root {
  .modal-paper {
    border-radius: 8px !important;
    background-color: var(--bg-modal);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    color: var(--text-body-color);
    padding: 10px 0px;
    font-size: 14px;
    z-index: 1;
    min-width: 85px;
    .modal-item {
      cursor: pointer;
      padding: 5px 10px;
      color: var(--color-label-4);
      &:hover {
        color: var(--color-primary-1);
      }
    }
    .active {
      color: var(--color-primary-1);
    }
  }
}
