:root {
  .wrapper-input {
    display: flex;
    align-items: center;
    background: var(--background-table-header);
    border-radius: 8px;
    padding: 14px 15px;
    height: 21px;
    flex-shrink: 0;
    width: 270px;
  }
  .wrapper-input-responsive {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    flex-shrink: 0;
    > *:first-child {
      width: 50%;
    }
    > *:last-child {
      width: 50%;
    }
  }
  .input-responsive-item {
    display: flex;
    flex-direction: column;
    > *:last-child {
      margin-top: 15px;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
    }
    .text-middle {
      font-size: 18px;
      color: var(--color-label-6);
      padding: 0 8px;
      white-space: nowrap;
    }
    @media only screen and (max-width: 912px) {
      > *:last-child {
        margin-top: 15px;
        background: var(--background-table-header);
        border-radius: 8px;
        padding: 14px 0px;
      }
    }
  }
  .date-item {
    display: flex;
  }
  .input-time {
    width: 100%;
    border: none;
    outline: none;
    background: var(--background-table-header);
    font-size: 18px;
    color: var(--color-label-4);
    cursor: pointer;
    padding: 0 !important;
    @media only screen and (max-width: 330px) {
      width: 86px;
    }
  }
  .text-middle {
    font-size: 18px;
    color: var(--color-text-middle);
    padding-right: 8px;
    white-space: nowrap;
  }

  .calendar-icon {
    position: relative;
    .input-ghost {
      width: 22px;
      height: 24px;
      border-radius: 5px;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 970px) {
    .text-middle {
      font-size: 14px !important;
    }
    .input-time {
      font-size: 14px !important;
    }
  }
  @media only screen and (max-width: 912px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 849px;
    }
  }
  @media only screen and (max-width: 820px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 757px;
    }
  }
  @media only screen and (max-width: 768px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 706px;
    }
  }
  @media only screen and (max-width: 540px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 477px;
    }
  }
  @media only screen and (max-width: 414px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 352px;
    }
  }
  @media only screen and (max-width: 393px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 331px;
    }
  }
  @media only screen and (max-width: 390px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 328px;
    }
  }
  @media only screen and (max-width: 375px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 360px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 297px;
    }
  }
  @media only screen and (max-width: 280px) {
    .wrapper-input {
      display: flex;
      align-items: center;
      background: var(--background-table-header);
      border-radius: 8px;
      padding: 14px 15px;
      width: 218px;
    }
    .input-time {
      width: 70px;
      border: none;
      outline: none;
      background: var(--background-table-header);
      font-size: 18px;
      color: var(--color-place-holder);
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
