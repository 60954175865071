:root {
  .wrapper-title {
    @media only screen and (max-width: 300px) {
      padding: 16px 14px 10px 14px;
    }
  }

  .gap-xs {
    @media only screen and (max-width: 300px) {
      gap: 40px;
    }
  }

  .dialog-title {
    text-align: left;
    font-weight: 600;
    font-size: 34px;
    color: var(--color-title-dialog);
    margin-top: 3px;

    @media only screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  .dialog-content {
    @media only screen and (max-width: 300px) {
      padding: 0px 14px;
    }
  }

  .content-wrapper {
    padding-top: 0px;
    @media only screen and (max-width: 300px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .margin-top-bottom-9 {
    padding: 6px 0;
    margin-top: 7px;
    color: var(--color-modal-desc) !important;
    @media only screen and (max-width: 300px) {
      font-size: 14px;
    }
  }

  .text-note {
    font-size: 18px;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .text-entire {
    font-size: 18px;
    color: var(--color-value-1) !important;
    margin-bottom: 34px;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
      margin-bottom: 14px;
    }
  }

  .text-fold {
    color: var(--color-modal-desc) !important;
    white-space: nowrap;
    font-size: 18px;
    @media only screen and (max-width: 400px) {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .App-positive-value {
    color: var(--color-primary-2) !important;
    font-size: 18px !important;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }
  .App-negative-value {
    color: var(--color-short-price) !important;
    font-size: 18px !important;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }

  .text-modal {
    font-size: 16px !important;
    color: var(--color-value-1) !important;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }
  .text-price {
    font-size: 18px;
    color: var(--color-label-4);
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }
  .text-price-value {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-value-1) !important;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }

  .take-profit-input,
  .stop-loss-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    @media only screen and (max-width: 400px) {
      justify-content: flex-start !important;
      gap: 8px;
    }
    @media only screen and (max-width: 300px) {
      margin-top: 14px;
    }
  }
  .wrap-text {
    flex-wrap: wrap;
  }

  .input-group {
    width: 72%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    height: 49px;
    background: var(--color-input-modal);

    .input-label {
      width: 100%;
      padding-left: 15px;
      background: var(--color-input-modal);
      color: var(--color-label-4);
      height: 49px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      @media only screen and (max-width: 400px) {
        font-size: 14px;
        white-space: nowrap;
      }
    }
    .input {
      padding-left: 0px;
      text-align: end;
      font-size: 18px;
      background: var(--color-input-modal);
      height: 100% !important;
      color: var(--color-value-1);
    }
    .input-wrapper {
      border: none;
      padding-right: 4px;
      width: 100%;
      ::placeholder {
        text-align: end;
        padding-left: 10px;
        color: var(--color-modal-desc);
      }
    }
    .input-not-label {
      padding-left: 15px;
      text-align: start;
      font-size: 20px;
      background: var(--color-input-modal);
      height: 100% !important;
      color: var(--color-value-1);
    }
  }

  .error-price {
    border: 2px solid var(--color-background-message_error);
  }

  .trigger-value {
    width: 46%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;

    .select-wrapper {
      width: 150px;
    }
    .choose-trigger {
      background: var(--color-background-select);
    }
  }

  .trigger-price {
    width: 22%;
    display: flex;
    justify-content: space-between;
    background: var(--color-input-modal);
    height: 49px;
    align-items: center;
    border-radius: 8px;
    .select-wrapper {
      padding-left: 14px !important;
    }
    .choose-trigger {
      border: none !important;
      width: 130px !important;
      font-size: 18px !important;
      @media only screen and (max-width: 770px) {
        width: 98px !important;
      }
      @media only screen and (max-width: 400px) {
        width: 80px !important;
      }
    }
    @media only screen and (max-width: 400px) {
      width: 85px !important;
    }
  }

  .take-profit-select {
    width: 75px;
    @media only screen and (max-width: 770px) {
      width: 80px;
    }
  }

  .message-error {
    margin-top: 5px;
    color: var(--color-error);
    @media only screen and (max-width: 300px) {
      font-size: 14px;
    }
  }

  .btn-cancel {
    padding: 10px 20px;
    font-style: normal;
    border-radius: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-left: 24px;
    border: none;
    cursor: pointer;
    color: var(--color-connect-wallet-input-border);
    background-color: var(--color-background-order-form_cross);
    &:hover {
      transition: 0.5s;
      background-color: var(--color-background-button_confirm_hover);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--color-label-2);
      color: var(--color-background-text_button_confirm);
      background-color: var(--color-background-button_confirm);
    }
  }

  .dialog-action {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 300px) {
      padding: 14px 16px !important;
    }
    button {
      padding: 15px 0;
      width: 100%;
      font-family: inherit;
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      border: none;
      cursor: pointer;
      color: var(--color-connect-wallet-input-border);
      background-color: var(--color-background-order-form_cross);
      text-transform: capitalize;
      &:hover {
        transition: 0.5s;
        background-color: var(--color-background-button_confirm_hover);
      }
      @media only screen and (max-width: 400px) {
        font-size: 16px !important;
      }

      // &:disabled {
      //   cursor: not-allowed;
      //   color: var(--color-label-2);
      //   color: var(--color-background-text_button_confirm);
      //   background-color: var(--color-background-button_confirm);
      // }
    }
  }

  .disable {
    cursor: not-allowed;
  }

  .text-conditions {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: var(--color-date-value) !important;
    white-space: nowrap;
  }

  @media only screen and (max-width: 768px) {
    .dialog-title {
      font-size: 20px;
      line-height: 27px;
      text-align: center;
    }
    .text-fold {
      font-size: 14px;
      line-height: 19px;
    }
    .App-positive-value,
    .App-negative-value {
      font-size: 14px !important;
      line-height: 19px;
    }
    .text-modal {
      font-size: 14px !important;
      line-height: 19px;
    }
    .text-note,
    .text-entire {
      font-size: 14px !important;
      line-height: 19px;
    }
    .trigger-price {
      width: 26%;
      .select-wrapper {
        padding-left: 10px !important;
      }
    }
    .input-group {
      width: 71%;
      .input,
      .input-label,
      .input-not-label {
        font-size: 16px !important;
        line-height: 21px;
        white-space: nowrap;
      }
    }
    .text-conditions {
      font-size: 16px !important;
    }
    .btn-cancel {
      font-size: 16px;
    }
  }
}
