:root {
  .select-wrapper {
    position: relative;
    display: none;
    -webkit-tap-highlight-color: transparent;
    @media only screen and (max-width: 970px) {
      display: block;
      position: unset;
    }

    > ul {
      & > li::marker {
        content: '';
      }
    }

    > ul {
      display: inline;
      margin: 0;
      padding: 0;
    }

    .select-title {
      color: var(--color-text);
      font-size: 16px;
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding: 7px 0px;
      white-space: nowrap;

      &::marker {
        content: none;
      }

      .icon {
        margin-bottom: 1px;
        margin-left: 5px;
      }
      &:hover {
        > svg path {
          fill: var(--color-primary-1);
        }
      }
    }
    .option {
      position: absolute;
      min-height: auto;
      background-color: var(--bg-navbar);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 15px;
      right: -60px;
      z-index: 99999;

      @media only screen and (max-width: 970px) {
        right: 15px;
      }

      > li {
        display: block;
        &:nth-child(2) {
          margin-top: 12px;
        }
      }

      .option-text {
        font-size: 16px;
        color: var(--text-item-select-header);
        margin-left: 15px;
        text-align: left;
        white-space: nowrap;
        width: 100%;
      }

      .option-value {
        height: 49px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding-right: 60px;

        &:hover {
          border-radius: 8px;
          background-color: var(--bg-hover-item-select-header);
          .link-icon {
            display: block;
          }
        }

        > a {
          text-decoration: none;
          display: flex;
        }

        .link-icon {
          display: none;
          margin-bottom: 5px;
          position: absolute;
          right: 0;
          margin-right: 15px;
        }
      }
    }

    .ul {
      display: inline;
      margin: 0;
      padding: 0;
    }
  }
}
