:root {
  .title-trading-rule {
    padding: 20px 0 0 20px;
    background-color: var(--bg-modal-pre);
    @media only screen and (max-width: 300px) {
      padding: 14px 0 0 10px;
    }
  }

  .content {
    display: flex;
    width: 910px;
    height: 59vh;
    padding: 0;
    overflow: hidden;
    background-color: var(--bg-modal-pre);
    @media only screen and (max-width: 920px) {
      flex-direction: column;
      width: 100%;
    }
    @media only screen and (max-width: 400px) {
      height: 100%;
    }
    .tab-scroll {
      @media only screen and (max-width: 630px) {
        overflow-x: auto;
        width: 375px;
      }
    }
    .tab-preference {
      border: 4px solid var(--border-pre);
      @media only screen and (max-width: 920px) {
        display: flex;
        border: none;
      }
      @media only screen and (max-width: 630px) {
        overflow-x: auto;
        // width: 340px;
      }
      // @media only screen and (max-width: 400px) {
      //   overflow-x: auto;
      //   width: 250px;
      // }
      // @media only screen and (max-width: 300px) {
      //   overflow-x: auto;
      //   width: 220px;
      // }
      // @media only screen and (max-width: 280px) {
      //   overflow: auto;
      //   width: 204px;
      // }
      .tab-items {
        font-size: 16px;
        color: var(--text-tab-active);
        width: 230px;
        height: 49px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 40px;
        @media only screen and (max-width: 920px) {
          width: fit-content;
          justify-content: center;
          padding-left: 16px;
          padding-right: 16px;
          white-space: nowrap;
        }
      }
      .tab-items-active {
        background: var(--bg-tab-general);
        color: var(--text-general-active);
        border-right: 4px solid #00ddb3;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        @media only screen and (max-width: 920px) {
          border-bottom: 2px solid #00ddb3;
          border-right: none;
          background: none;
        }
      }
    }
    .tab-detail {
      border-top: 4px solid var(--border-pre);
      @media only screen and (max-width: 920px) {
        overflow: auto;
      }
      @media only screen and (max-width: 630px) {
        max-height: 430px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
    .order-content {
      display: flex;
      flex-direction: column;
      gap: 22px;
      padding: 40px 40px 0 40px;
      overflow-y: auto;
      @media only screen and (max-width: 920px) {
        padding: 24px 16px 0 16px;
      }
      @media only screen and (max-width: 300px) {
        padding: 14px 10px 0 10px;
      }
      .option-order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .option-text {
          font-size: 18px;
          color: var(--text-tab);
          @media only screen and (max-width: 920px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 300px) {
            font-size: 12px;
          }
        }
        .option-value {
          font-size: 18px;
          color: var(--text-tab-active);
          font-weight: 600;
          text-align: end;
          @media only screen and (max-width: 920px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 300px) {
            font-size: 12px;
          }
        }
        .contract {
          display: flex;
          align-items: center;
          .text-coin {
            font-size: 18px;
            font-weight: 600;
            color: var(--text-tab-active);
            margin-left: 8px;
            @media only screen and (max-width: 920px) {
              font-size: 16px;
            }
          }
          img {
            border-radius: 50%;
            width: auto;
            height: 30px;
          }
          .text-contract {
            color: var(--text-tab);
            font-size: 18px;
            margin-left: 4px;
            @media only screen and (max-width: 920px) {
              font-size: 16px;
            }
          }
        }
        .text-no {
          color: var(--text-tab);
          font-size: 18px;
          @media only screen and (max-width: 920px) {
            font-size: 16px;
          }
        }
      }
      .empty {
        width: 548px;
        height: 2px;
        background-color: #ffffff;
      }
      .empty-coin {
        width: 548px;
        height: 2px;
        background-color: var(--bg-modal-pre);
        padding-bottom: 10px;
        @media only screen and (max-width: 920px) {
          width: 0px;
          height: 0px;
        }
      }
      .empty-trading {
        width: 548px;
        height: 2px;
        background-color: var(--bg-modal-pre);
        padding-bottom: 40px;
        @media only screen and (max-width: 920px) {
          width: 0px;
          height: 0px;
          padding-bottom: 40px;
        }
      }
      .note {
        font-size: 16px;
        color: var(--color-label-2);
      }
      .note-funding {
        position: relative;
        font-size: 16px;
        color: var(--color-label-2);
        // display: flex;
        .edit-btn {
          background-color: var(--color-background-order-form_filter);
          width: fit-content;
          border: none;
          cursor: pointer;
          > img {
            display: flex;
            align-items: center;
          }
        }
      }
      .funding-tooltip {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(25, 25, 25, 0.8);
        border-radius: 8px;
        width: 225px;
        padding: 10px;
        color: #ffffff;
        bottom: 39%;
      }
      .funding-input {
        position: relative;
        .input-value {
          position: relative;
          border: none;
          border-radius: 8px;
          background-color: var(--background-spline-graph);
          padding: 15px 0px 15px 15px;
          width: 377px;
          position: relative;
          font-size: 18px;
          color: var(--color-value-1);
          &:focus-visible {
            outline: none;
          }
        }
        .edit-icon {
          position: absolute;
          right: 40px;
          top: 12px;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            &:nth-child(1) {
              margin-right: 17px;
              margin-top: 3px;
            }
            &:nth-child(2) {
              margin-right: 7px;
              cursor: pointer;
            }
            &:nth-child(3) {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .select-wrapper {
    position: relative;
    width: fit-content;
  }
  .coin-wrapper {
    display: flex;
    @media only screen and (max-width: 920px) {
      flex-direction: column;
    }
  }
  .select-value {
    border-radius: 8px;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    gap: 8px;
    color: var(--text-tab-inactive);
    cursor: pointer;
    margin: 0 0 14px 20px;
    align-items: center;
    @media only screen and (max-width: 920px) {
      margin: 0;
    }
    .select-name {
      font-size: 24px;
      font-weight: 600;
      color: var(--text-select);
      user-select: none;
      font-family: 'inter-regular';
      @media only screen and (max-width: 920px) {
        font-size: 16px;
      }
    }
    .icon-up {
      transform: rotate(3.142rad);
    }
    .icon-down {
      @media only screen and (max-width: 920px) {
        width: 8px;
        height: 8px;
        // position: relative;
        // top: -5px;
      }
    }
  }
  .select-search {
    display: flex;
    gap: 12px;
    background: var(--bg-select-search);
    padding: 10px 15px;
    border-radius: 8px;
    input {
      border: none;
      background-color: transparent;
      width: 80%;
      font-weight: 400;
      font-size: 18px;
      color: var(--text-tab-active);
      @media only screen and (max-width: 920px) {
        font-size: 14px;
      }
      &:focus-visible {
        outline: none !important;
      }
      &::placeholder {
        font-size: 16px;
      }
    }
  }
  .select-item {
    padding-top: 12px;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-item-search);
    &:hover {
      color: var(--color-primary-1);
    }
    @media only screen and (max-width: 920px) {
      font-size: 14px;
    }
  }

  .select-item-active {
    color: var(--color-primary-1) !important;
  }
  
  .select-list {
    margin: 0 0 20px 20px;
    position: absolute;
    background: var(--bg-select);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 210px;
    padding: 15px;
    padding-bottom: 24px;
    z-index: 99;
    top: 45px;
    max-height: 300px;
    overflow-y: auto;
    @media only screen and (max-width: 920px) {
      margin: 0 0 20px 0px;
      top: 28px;
    }
  }
  .item-last-price {
    font-size: 24px;
    font-weight: 600;
    margin-left: 40px;
    color: var(--color-last-price);
    font-family: 'inter-regular';
    @media only screen and (max-width: 920px) {
      font-size: 16px;
      margin-left: 0px;
    }
  }
  .item-value-change {
    display: flex;
    font-size: 18px;
    margin-left: 8px;
    margin-top: 7px;
    color: var(--text-title-color);
    font-family: 'inter-regular';
    .price-change-percent {
      margin-left: 10px;
    }
    @media only screen and (max-width: 920px) {
      font-size: 14px;
      margin-top: 0px;
      align-items: center;
    }
  }
  .no-result-found {
    font-size: 14px;
    color: var(--text-tab-inactive);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 12px;
  }
  .explorer {
    display: flex;
    gap: 4px;
    > img {
      cursor: pointer;
    }
  }
  .funding-wrapper {
    padding: 20px 30px 0 40px;
    @media only screen and (max-width: 920px) {
      padding: 15px 15px 0 15px;
    }
    @media only screen and (max-width: 400px) {
      overflow-x: hidden;
    }
    .funding-rate {
      font-size: 18px;
      color: var(--text-tab-active);
      font-weight: 600;
      margin-bottom: 24px;
      @media only screen and (max-width: 920px) {
        font-size: 16px;
      }
    }
    .funding-rate-table {
      height: 250px;
      margin-top: 24px;
      overflow-y: auto;
      overflow-x: auto;
      .table-header {
        margin: 0px 0 15px;
        border-radius: 5px;
        width: 100%;
        text-align: left;
        border-spacing: 0px;
        height: 300px;
        > thead {
          width: 100%;
          position: sticky;
          top: 0;
          z-index: 1;
          @media only screen and (max-width: 400px) {
            top: -2px;
          }
          > tr {
            padding: 14px 0 14px 24px;
            background-color: var(--border-pre);
            border-radius: 8px;
            > th {
              color: var(--text-tab);
              font-size: 14px;
              padding-right: 30px;
              font-weight: 400;
              opacity: 0.8;
              white-space: nowrap;
              padding: 14px 24px;
            }
          }
        }
      }
      .wrapper-table-funding {
        // height: 210px;
        // overflow-y: auto;
        // overflow-x: scroll;
      }
      .table-funding {
        width: 100%;
        text-align: left;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      .pagination-custom {
        > ul {
          > li {
            > button {
              color: var(--color-value-1);
              font-size: 14px;
              font-weight: 600;
              border: 1px solid #dedede !important;
              font-family: inherit;
            }
          }
        }
      }
    }
    .empty-funding {
      height: 2px;
      width: 550px;
      padding-bottom: 40px;
    }
  }
  .tier-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-tab-active);
    border-top: 2px solid #f8f8f8;
    padding-top: 24px;
    @media only screen and (max-width: 920px) {
      padding-top: 14px;
    }
    @media only screen and (max-width: 300px) {
      font-size: 16px;
    }
    &:nth-child(1) {
      border-top: none;
    }
  }
  .wrapper-leverage {
    height: 100%;
    overflow-y: auto;
  }
  .leverage-content {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 0px 40px 0 40px;
    // overflow-y: auto;
    overflow-x: hidden;
    @media only screen and (max-width: 920px) {
      padding: 0px 16px 0 16px;
    }
    @media only screen and (max-width: 300px) {
      padding: 0px 10px 0 10px;
      gap: 15px;
    }
    .empty-trading {
      width: 548px;
      height: 2px;
      background-color: var(--border-pre);
      @media only screen and (max-width: 920px) {
        width: 0px;
        height: 0px;
      }
    }
  }
  .leverage-form {
    display: flex;
    justify-content: space-between;
    .leverage-label {
      color: var(--text-tab);
      font-size: 16px;
      @media only screen and (max-width: 920px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 400px) {
        width: 169px;
        user-select: none;
      }
      @media only screen and (max-width: 300px) {
        font-size: 12px;
      }
    }
    .leverage-value {
      color: var(--text-tab-active);
      font-size: 16px;
      font-weight: 600;
      text-align: right;
      @media only screen and (max-width: 920px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 300px) {
        font-size: 12px;
      }
    }
  }
  .icon-close-modal {
    margin-right: 40px !important;
    @media only screen and (max-width: 920px) {
      margin-right: 16px !important;
    }
  }
  .body-table {
    // overflow-y: auto;
  }
  .table-row {
    width: 100%;
  }
  .table-value {
    font-size: 16px;
    color: var(--text-tab-active);
    white-space: nowrap;
    padding: 15px 24px;
    @media only screen and (max-width: 920px) {
      font-size: 14px;
    }
    &:nth-child(2) {
      margin-left: 57px;
      width: 129px;
    }
    &:nth-child(3) {
      margin-left: 167px;
      width: 129px;
    }
  }
  .check-tooltip {
    position: relative;
    &:hover {
      .tooltip-position {
        display: block;
      }
    }
    .tooltip-position {
      display: none;
      position: absolute;
      width: 270px;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      padding: 10px;
      z-index: 1000;
    }
  }
  .time-change {
    display: flex;
  }

  .no-scroll {
    -webkit-overflow-scrolling: touch;
  }

  .no-scroll::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
  .icon-direct {
    width: 40px;
    margin: 0 10px;
    cursor: pointer;
    rect {
      fill: transparent;
    }
    path {
      stroke: var(--color-label-2);
    }
  }
  .icon-prev {
    transform: rotate(180deg);
  }

  .tab-mobile {
    display: flex;
    align-items: center;
  }
}
