:root {
  .wrapper-title {
    @media only screen and (max-width: 300px) {
      padding: 16px 14px 0px 14px;
    }
  }

  .dialog-title {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: var(--color-value-1);
    @media only screen and (max-width: 300px) {
      font-size: 20px;
      text-align: start;
    }
  }

  .wrapper-content {
    @media only screen and (max-width: 300px) {
      padding: 0px 14px 16px 14px;
    }
  }

  .margin-top-bottom-9 {
    padding: 6px 0;
    margin-top: 7px;
    @media only screen and (max-width: 300px) {
      align-items: center;
    }
  }

  .align-items {
    align-items: center;
  }

  .text-desc {
    text-align: center;
  }

  .text-order-box {
    font-size: 16px !important;
    color: var(--color-modal-desc) !important;
    @media only screen and (max-width: 300px) {
      font-size: 14px !important;
      text-align: start;
    }
  }

  .text-modal {
    font-size: 16px !important;
    color: var(--color-value-1) !important;
    @media only screen and (max-width: 300px) {
      font-size: 14px !important;
    }
  }

  .App-positive-value {
    color: var(--color-primary-2) !important;
  }
  .App-negative-value {
    color: var(--color-short-price) !important;
  }

  .button-wrapper {
    width: 100%;
    font-size: 18px;
    position: relative;
    margin-bottom: 0px;
    .confirm-close-btn {
      width: 100%;
      background: var(--color-background-order-form_cross);
      text-transform: unset;
      height: 50px;
      color: var(--color-btn-cal);
      border-radius: 8px;
      font-size: 18px !important;
      font-family: inherit;
    }
  }

  .box-wrapper {
    width: 270px;
    margin: auto;
    background: var(--color-order-box);
    border-radius: 8px;
    padding: 20px;
    @media only screen and (max-width: 300px) {
      padding: 10px;
      width: auto;
    }
  }

  .stop-price-wrapper {
    position: relative;
  }

  .stop-price-input {
    display: flex;
    background: var(--color-input-stop-price);
    border-radius: 8px;
    .input-value {
      border: none !important;
      border-radius: 8px;
      border: 1px solid #f8f8f8;
      padding: 15px 15px 15px 15px;
      width: 65px;
      position: relative;
      font-size: 18px;
      color: var(--color-value-1);
      background: var(--color-input-stop-price);
      &:focus-visible {
        outline: none;
      }
    }

    .stop-price-btn {
      display: flex;
      align-items: center;
      .close-icon {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-right: 10px;
        cursor: pointer;
      }
      button {
        background-color: transparent;
        margin-right: 12px;
        border: none;
        display: flex;
        align-items: center;
        .edit-icon {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .stop-price-error {
    position: absolute;
    width: 145px;
    display: flex;
    align-items: center;
    background: var(--bg-tooltip-1);
    border-radius: 8px;
    color: #ffffff;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    z-index: 1;
    right: 25px;
  }

  .edit-stop-price {
    cursor: pointer;
    margin-left: 5px;
  }

  .input-value-text {
    color: var(--color-value-1);
  }

  .trigger-order {
    width: 110px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--line);
    border: none;
    position: relative;
    left: 18px;
    .select-wrapper {
      width: 150px;
    }
    .choose-trigger {
      border-radius: 4px;
    }
  }
  .disabled-select {
    width: 96px;
  }

  .text-bold {
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    .dialog-title {
      font-size: 24px !important;
    }
    .text-desc {
      font-size: 16px !important;
    }
    .text-header {
      font-size: 18px !important;
    }
    .text-order-box,
    .text-modal {
      font-size: 16px !important;
    }
    .App-positive-value,
    .App-negative-value {
      font-size: 16px !important;
    }
    .input-value-text {
      font-size: 16px !important;
    }
    .button-wrapper {
      .confirm-close-btn {
        font-size: 16px !important;
      }
    }
    .trigger-order {
      left: 10px;
      .choose-trigger {
        width: 110px;
      }
    }
    .box-wrapper {
      margin: 10px auto;
      width: 240px;
    }
  }
}
