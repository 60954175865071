:root {
  .select-value {
    background: var(--color-select-value);
    border-radius: 8px;
    padding: 14px 15px;
    width: 243px;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-tab-inactive);
    cursor: pointer;
    justify-content: space-between;
    user-select: none;
    .place-holder {
      display: block;
      font-size: 18px;
      color: var(--color-label-2);
    }
    .place-holder-hide {
      display: none;
      font-size: 18px;
      color: var(--color-label-2);
    }
    .filter-action {
      display: flex;
      gap: 10px;
      align-items: center;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--color-value-1);
      }
      .iconDown {
        path {
          fill: var(--text-tab-active);
        }
      }
    }
  }
  .select-value-no-first {
    border-radius: 8px;
    padding: 14px 0px;
    width: 100%;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    gap: 10px;
    color: var(--text-tab-inactive);
    cursor: pointer;
    justify-content: flex-end;
    user-select: none;
    margin-left: 22px;
    .place-holder {
      display: block;
      font-size: 18px;
      color: var(--color-label-2);
    }
    .place-holder-hide {
      display: none;
      font-size: 18px;
      color: var(--color-label-2);
    }
    .iconDown {
      path {
        fill: var(--text-tab-active);
      }
    }
  }
  .select-list {
    position: absolute;
    background: var(--color-select-list);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    z-index: 10000;
    top: 49px;
    max-height: 265px;
    overflow-y: auto;
    > *:last-child {
      padding-bottom: 15px;
    }
  }
  .select-wrapper {
    position: relative;
    width: fit-content;
  }
  .select-search {
    display: flex;
    gap: 12px;
    background: var(--color-background-select-search);
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
    input {
      border: none;
      background-color: transparent;
      width: 80%;
      font-weight: 400;
      font-size: 18px;
      color: var(--color-text);
      &::placeholder {
        color: var(--color-select-search-placeholder);
      }
      &:focus-visible {
        outline: none !important;
      }
    }
  }
  .select-item {
    padding: 20px 15px 0px 15px;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-tab-inactive);
    &:hover {
      color: #00ddb3;
    }
  }

  .select-item-active {
    color: #00ddb3;
  }

  .no-result {
    padding: 20px 15px 0px 15px;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-tab-inactive);
  }

  @media only screen and (max-width: 970px) {
    .select-item {
      font-size: 14px;
    }
    .select-value {
      background: var(--color-select-value);
      border-radius: 8px;
      padding: 14px 15px;
      display: flex;
      font-weight: 400;
      width: 100%;
      font-size: 18px;
      color: var(--text-tab-inactive);
      cursor: pointer;
      justify-content: space-between;
      user-select: none;
      .place-holder {
        display: block;
        font-size: 18px;
        color: var(--color-label-2);
      }
      .place-holder-hide {
        display: none;
        font-size: 18px;
        color: var(--color-label-2);
      }
      .filter-action {
        display: flex;
        gap: 10px;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--color-value-1);
        }
      }
    }
  }
  .rotate {
    transform: rotate(3.142rad);
  }
}
