:root {
  .wrapper {
    height: 25px;
    width: 51.6px;
    border-radius: 16px;
    background-color: var(--bg-switch);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    cursor: pointer;
    box-sizing: border-box;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    @media only screen and (max-width: 500px) {
      height: 20px;
      width: 41.6px;
    }

    &__switch {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: var(--bg-switch-btn);
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 200ms;
      box-sizing: border-box;
      &__enable {
        transform: translate(142%, 0);
        background-color: var(--bg-switch-enable);
      }
      @media only screen and (max-width: 500px) {
        height: 15.2px;
        width: 15.2px;
      }
    }

    &__enabled {
      background-color: var(--switch-enable);
    }

    &__disabled {
      background-color: var(--switch-disable);
    }
  }
}
