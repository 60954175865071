:root {
  .title-prefer {
    padding: 26px 27px 20px 40px;
    background-color: var(--bg-modal-pre);
    @media only screen and (max-width: 500px) {
      padding: 24px 27px 20px 16px;
    }
  }

  .head-preference {
    font-size: 34px;
    font-weight: 600;
    color: var(--color-text);
    @media only screen and (max-width: 800px) {
      font-size: 20px;
    }
  }

  .content {
    display: flex;
    width: 656px;
    min-height: 398px;
    padding: 0;
    background-color: var(--bg-modal-pre);

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      width: fit-content;
      height: 100%;
    }

    .tab-preference {
      border: 1px solid var(--border-pre);
      @media only screen and (max-width: 800px) {
        display: flex;
        align-items: baseline;
        justify-content: center;
      }
      @media only screen and (max-width: 500px) {
      }
      .tab-items {
        font-size: 16px;
        color: var(--text-tab);
        width: 157px;
        height: 35px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:nth-child(1) {
          margin-top: 20px;
        }
        padding-left: 40px;

        @media only screen and (max-width: 800px) {
          justify-content: center;
          padding-left: 0px;
          white-space: nowrap;
          width: 150px;
          &:nth-child(1) {
            margin-top: 0px;
          }
        }
      }

      .tab-items-active {
        background: var(--border-pre);
        color: var(--text-tab-active);
        @media only screen and (max-width: 800px) {
          background: none;
          border-bottom: 2px solid #00ddb3;
        }
      }
    }

    .tab-detail {
      border-top: 1px solid var(--border-pre);
    }

    .order-content {
      display: flex;
      flex-direction: column;
      gap: 22px;
      padding: 22px 40px 40px 22px;
      @media only screen and (max-width: 500px) {
        padding: 22px 16px 0 16px;
      }
      .option-order {
        display: flex;
        justify-content: space-between;

        .option-text {
          font-size: 18px;
          color: var(--text-tab-active);
          @media only screen and (max-width: 500px) {
            font-size: 16px;
          }
        }
      }

      .note {
        font-size: 16px;
        color: var(--text-tab);
        @media only screen and (max-width: 800px) {
          font-size: 14px;
          margin-bottom: 30px;
        }
      }

      .note-noti {
        font-size: 16px;
        color: var(--text-tab);
        @media only screen and (max-width: 500px) {
          font-size: 14px;
        }
      }

      .notice {
        font-size: 14px;
        color: var(--color-label-2);
        margin-top: -10px;
      }

      .note-funding {
        &__item {
          display: inline-flex;
          align-items: center;
          min-height: 20px;
        }

        position: relative;
        font-size: 16px;
        color: var(--text-tab);
        @media only screen and (max-width: 500px) {
          font-size: 14px;
        }
        .edit-btn {
          background-color: transparent;
          width: fit-content;
          border: none;
          cursor: pointer;
          margin-top: 4px;
          &:disabled {
            cursor: not-allowed;
          }
        }

        .text-fee {
          color: var(--color-text);
        }
      }

      .funding-tooltip {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(25, 25, 25, 0.8);
        border-radius: 8px;
        width: 225px;
        padding: 10px;
        color: #ffffff;
        bottom: 39%;
      }

      .funding-input {
        position: relative;
        .input-value {
          position: relative;
          border: none;
          border-radius: 8px;
          background-color: var(--bg-input-notification);
          padding: 15px 0px 15px 15px;
          width: 377px;
          position: relative;
          font-size: 18px;
          color: var(--color-value-1);
          &:focus-visible {
            outline: none;
          }
          @media only screen and (max-width: 400px) {
            width: 95%;
          }
        }

        .edit-icon {
          position: absolute;
          right: 15px;
          top: 12px;
          display: flex;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            &:nth-child(1) {
              margin-right: 17px;
              margin-top: 3px;
            }
            &:nth-child(2) {
              margin-right: 7px;
              cursor: pointer;
            }
            &:nth-child(3) {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
